/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "daemon",
            "endpoint": "https://99woccbet5.execute-api.us-east-1.amazonaws.com",
            "region": "us-east-1"
        },
        {
            "name": "oshiAPI",
            "endpoint": "https://oy46bwdxic.execute-api.us-east-1.amazonaws.com/newmain",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://ndvrlf432zhybkcv5lt5ez4kre.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-x5ai6q2ag5ahpdzqmv4nmceule",
    "aws_cognito_identity_pool_id": "us-east-1:b9418b89-ef50-400d-8234-0a33a9a9e868",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_8KWHvuYFg",
    "aws_user_pools_web_client_id": "5et6476cjtttsecfq7eavh6113",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "authsessions-newmain",
            "region": "us-east-1"
        },
        {
            "tableName": "cacheddata-newmain",
            "region": "us-east-1"
        },
        {
            "tableName": "extradata-newmain",
            "region": "us-east-1"
        },
        {
            "tableName": "multimediameta-newmain",
            "region": "us-east-1"
        },
        {
            "tableName": "queueschedule-newmain",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "multimedia95415-main",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "fc9867f36d4f468686c795f09773a7ad",
            "region": "us-east-1"
        }
    }
};


export default awsmobile;