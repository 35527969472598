import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';

import { palette } from '../theme/palette';
import Modal from './Modal';
import Button from './Button';
import useWindowSize from '../hooks/useWindowSize';

const SkipCardscanModal = ({ onSkip, onCancel }) => {
  const { screenSize } = useWindowSize();
  return (
    <Modal
      containerStyle={{
        width: screenSize.width < 423 ? 'calc(100% - 48px)' : '375px',
        height: 'max-content',
      }}
      id='skipCardscanModal'
      hideCloseButton
      ariaRole='alertdialog'
      ariaLabelledBy='skipModalTitle'
      ariaDescribedBy='skipModalDesc'
      onClose={onCancel}
    >
      <ModalContentContainer>
        <CircleIcon>
          <FontAwesomeIcon
            aria-hidden='true'
            icon={faTriangleExclamation}
            style={{ color: palette.turquoise200, fontSize: '32px' }}
          />
        </CircleIcon>
        <TitleText id='skipModalTitle'>Are you sure?</TitleText>
        <DescriptionText id='skipModalDesc'>
          By skipping this step now, you may need to manually enter your
          insurance card details as part of your intake forms.
        </DescriptionText>
        <Button
          type='button'
          onClick={onSkip}
          styles={{ width: 'fit-content', padding: '12px 24px' }}
        >
          Yes, Skip Insurance Info
        </Button>
        <CancelButton type='button' onClick={onCancel}>
          Go Back
        </CancelButton>
      </ModalContentContainer>
    </Modal>
  );
};

const ModalContentContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 24px;
`;

const CircleIcon = styled.div`
  align-items: center;
  background-color: ${palette.turquoise50};
  border-radius: 36px;
  display: flex;
  height: 72px;
  justify-content: center;
  width: 72px;
`;

const TitleText = styled.h1`
  color: ${palette.navy500};
  font-family: Usual;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
`;

const DescriptionText = styled.p`
  color: ${palette.navy500};
  font-family: Usual;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
`;

const CancelButton = styled.button`
  background: transparent;
  border: none;
  color: ${palette.navy500};
  font-family: Usual;
  font-size: 15px;
  font-weight: 600;
  margin: 8px 0;
  text-align: center;
  text-decoration: underline;
  width: 100%;
`;

export default SkipCardscanModal;
