import React from 'react';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';

import useCombobox from '../hooks/useCombobox';
import OshiSelectOption from './OshiSelectOption';
import Typography from '../atoms/Typography';
import { palette } from '../theme/palette';
import { ChevronDown2Icon } from '../assets/svgs';

const ITEM_HEIGHT = 40;
const MAX_ITEMS_SHOWN = 3;

/**
 * @function OshiSelect
 * @description Generic and reusable UI select component that behaves with fundamental functionality
 * of select-html-based element and web accessibility features.
 * It shows a list of select-based options when available. By default, the size of option list is limited
 * to show at most three options, the list is scrollable if more than three items are present in it.
 * @param {string} id - Unique identifier to link this component with a label and web accessibility features.
 * @param {string} label - Label text to be displayed on top of this component.
 * @param {Array<{value: string, label: string}>} data - List of options to be displayed as selectable options.
 * @param {string} placeholder - Custom placeholder text to be displayed as first unselectable option.
 * @param {string} storageKey - Custom key text to retrieve from Local Storage and display it as selectable option.
 * @param {(value: any): void} onChange - UI event that gets called when an select option is selected and passes option value data.
 * @param {styled.CSSObject} style - Custom CSS object style to customize this component root element.
 * @param {styled.CSSObject} labelStyles - Custom CSS object style to customize this component label element.
 * @returns {React.ReactElement}
 */
const OshiSelect = ({
  id,
  label,
  data = [],
  placeholder = '',
  storageKey = null,
  onChange,
  style = {},
  labelStyles = {},
}) => {
  const {
    labelRef,
    selectRef,
    popupListRef,
    expand,
    popupTopPosition,
    selectedItem,
    handleListExpand,
    handleListItemClick,
  } = useCombobox({
    id,
    data,
    placeholder,
    storageKey,
    onChange,
  });

  const animatedTitle = useSpring({ color: palette.coolGray });
  const countCmps = data?.length > 0 ? data?.length : 1;
  const expandHeight = countCmps * ITEM_HEIGHT;

  const popupExpandAnime = useSpring({
    config: { mass: 1, tension: 180, friction: 16 },
    height: expand
      ? `${
          countCmps > MAX_ITEMS_SHOWN
            ? ITEM_HEIGHT * MAX_ITEMS_SHOWN
            : expandHeight
        }px`
      : '0px',
  });

  const popupDisplayAnime = useSpring({
    display: expand ? 'block' : 'none',
  });

  const popupOverflowAnime = useSpring({
    overflowY: countCmps > MAX_ITEMS_SHOWN ? 'scroll' : 'initial',
  });

  const selectBackgroundAnime = useSpring({
    backgroundColor: palette.background,
    borderColor: expand ? palette.mediumTurqoise : palette.greyBorderColor,
  });

  const selectArrowIconAnime = useSpring({
    marginTop: expand ? '6px' : '0px',
    transform: expand ? 'scaleY(-1)' : 'scaleY(1)',
  });

  return (
    <Container style={style}>
      <Title
        id={`${id}Label`}
        ref={labelRef}
        style={{ ...animatedTitle, ...labelStyles }}
      >
        {label}
      </Title>
      <Select
        id={id}
        name={id}
        role='combobox'
        aria-haspopup='listbox'
        aria-controls={`${id}Listbox`}
        aria-expanded={expand}
        aria-labelledby={`${id}Label`}
        tabIndex={0}
        ref={selectRef}
        onClick={handleListExpand}
        style={{ ...selectBackgroundAnime }}
      >
        <TitleContainer>
          <SelectedOptionLabel id={`${id}SelectedOptionLabel`}>
            {selectedItem ? selectedItem.label : placeholder}
          </SelectedOptionLabel>
        </TitleContainer>
        <ChevronContainer style={selectArrowIconAnime}>
          <ChevronDown2Icon
            color={expand ? palette.mediumTurqoise : palette.coolGray500}
          />
        </ChevronContainer>
      </Select>
      <PopupList
        role='listbox'
        id={`${id}Listbox`}
        aria-labelledby={`${id}Label`}
        tabIndex={-1}
        ref={popupListRef}
        style={{
          ...popupDisplayAnime,
          ...popupExpandAnime,
          ...popupOverflowAnime,
          top: popupTopPosition,
        }}
      >
        {data.length > 0 ? (
          data.map((item, idx) => (
            <SelectOption
              key={item.value}
              id={`${id}${idx}`}
              value={item.value}
              label={item.label}
              onClick={() =>
                handleListItemClick(item)
              }
              aria-label={item.label}
              aria-selected={selectedItem && selectedItem.value === item.value}
            />
          ))
        ) : (
          <Typography
            type={'paragraph'}
            key={'no_data'}
            styles={{
              color: palette.coolGray500,
              textAlign: 'center',
              padding: '8px 12px',
              fontWeight: '400',
              userSelect: 'none',
            }}
          >
            No data
          </Typography>
        )}
      </PopupList>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  position: relative;
`;

const Select = styled(animated.div)`
  display: flex;
  position: relative;
  border-bottom: 2px solid;
  width: 100%;
  height: 41px;
  justify-content: center;
  align-items: center;
  margin-bottom: 14px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex: 1;
  height: 41px;
  align-items: center;
`;

const Title = styled(animated.label)`
  margin-bottom: 8px;
  font-size: 14px;
  position: relative;
  font-family: 'Usual';
  line-height: 18px;
  letter-spacing: 0.05em;
`;

const SelectOption = styled(OshiSelectOption)`
  &.focused {
    background-color: ${palette.turquoise50};
  }
`;

const SelectedOptionLabel = styled.div`
  font-size: 16px;
  font-family: 'Usual';
  line-height: 24px;
  font-weight: 400;
  color: ${palette.navy500};
`;

const ChevronContainer = styled(animated.div)`
  padding: 0 10px 0 20px;
`;

const PopupList = styled(animated.div)`
  display: flex;
  border: 2px solid;
  border-top: none;
  border-color: ${palette.coolGray200};
  flex-direction: column;
  height: 0px;
  width: 100%;
  position: absolute;
  z-index: 10;
  left: 0;
  background: ${palette.background};
`;

export default OshiSelect;
