import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { useFormik } from 'formik';

import { UserContext } from '../utils/context';
import getUrlParameter from '../utils/getUrlParameter';
import { palette } from '../theme/palette';
import {
  setIsFetching,
  setShowBanner,
  setShowRightSidedBannerVisibility,
} from '../actions';
import { ZERO_COST_PARTNERS } from '../constants/subscriptionStatus';
import { localStorageGet, localStorageSave } from '../utils/localStorageHelper';
import useComponentFocus from '../hooks/useComponentFocus';
import AnimatedRoute from '../components/AnimatedRoute';
import OshiNextButton from '../components/OshiNextButton';
import usePatientAPI from '../hooks/usePatientAPI';
import SymptomsTextArea from '../components/SymptomsTextArea';
import usePatientProfile from '../hooks/usePatientProfile';
import NewCustomTitle from '../components/NewCustomTitle';
import useWindowSize from '../hooks/useWindowSize';
import { ZeroCostMelonUnderline } from '../assets/svgs';
import ProviderVenn from '../assets/provider_venn.png';
import withTracker from '../wrappers/withTracker';

const AppointmentConfirm = ({
  setIsFetching,
  setShowRightSidedBannerVisibility,
  setShowBanner,
}) => {
  const history = useHistory();
  const { screenSize } = useWindowSize();
  const [shouldShowBanner, setShouldShowBanner] = useState(false);
  const [animationDirection, setAnimationDirection] = useState('mount'); // mount, back, forward
  const { patientProfile } = usePatientProfile();
  const native = getUrlParameter('platform', history) === 'native';
  const { appointmentType: appointmentTypeFromWebview } =
    localStorageGet('appointmentType');
  const userIdFromWebview = getUrlParameter('uid', history);
  const [headlineRef, setHeadlineFocus] = useComponentFocus();
  const { customPatientUpdate } = usePatientAPI();
  const symptomsLSValue = localStorageGet('symptoms') || '';
  const { loggedIn } = useContext(UserContext);

  const formik = useFormik({
    initialValues: {
      symptoms: symptomsLSValue || '',
    },
  });

  const enterSubmit = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleReserveAppt();
    }
  };

  const handleSymptomsChange = (ev) => {
    formik.handleChange(ev);
  };

  useEffect(() => {
    if (!patientProfile) {
      setIsFetching(true);
    } else {
      setIsFetching(false);
      setShouldShowBanner(
        ZERO_COST_PARTNERS.includes(patientProfile.partner_system_id)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientProfile]);

  useEffect(
    () => {
      if (!native && shouldShowBanner) {
        setShowBanner({
          cmp: (
            <BannerText>
              Covered by your employer at&nbsp;
              <ZeroCostContainer>
                <ZeroCost>$0 cost</ZeroCost>
                <ZeroCostUnderline>
                  <ZeroCostMelonUnderline />
                </ZeroCostUnderline>
              </ZeroCostContainer>
              &nbsp;to you.
            </BannerText>
          ),
          text: 'Covered by your employer at $0 cost to you!',
          sentiment: 'information',
        });
      }

      // eslint-disable-next-line no-undef
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });

      return () => {
        setShowBanner(false);
      };
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [shouldShowBanner]
  );

  useEffect(() => {
    headlineRef.current && setHeadlineFocus(headlineRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setHeadlineFocus]);

  // Always show right sided banner after privacy policy page
  useEffect(() => {
    setShowRightSidedBannerVisibility(false);
    // eslint-disable-next-line
  }, []);

  async function handleReserveAppt() {
    try {
      setIsFetching(true);
      if (formik.values.symptoms.length >= 3) {
        localStorageSave('symptoms', formik.values.symptoms);
        await customPatientUpdate(
          {
            symptoms: formik.values.symptoms,
          },
          userIdFromWebview
        );
      }

      history.push('first-appointment-book');
    } catch (error) {
      console.log('error', error);
    }
  }

  const getTitle = (title, subHeader) => {
    return loggedIn?.isUserAccountBeingCreated ? (
      <NewCustomTitle
        setShowBanner={null}
        hasZeroCostPartner={false}
        title={title}
        subHeader={subHeader}
      />
    ) : (
      <TitleContainer>
        <NewCustomTitle
          setShowBanner={null}
          hasZeroCostPartner={false}
          title={title}
          subHeader={subHeader}
        />
        {screenSize.width < 769 && screenSize.width > 685 && (
          <img
            src={ProviderVenn}
            width={241}
            alt='A three circle venn diagram'
          />
        )}
      </TitleContainer>
    );
  };

  const btnStyles = {};

  if (patientProfile) {
    btnStyles.width = '100%';
  }

  return (
    <PageContainer>
      <AnimatedRoute
        nextRoute='/first-appointment-confirm'
        title={getTitle(
          'Our GI providers are ready for you!',
          loggedIn?.isUserAccountBeingCreated
            ? `Please share why you're seeking care, including any symptoms you'd like to discuss.`
            : `Please list any gastrointestinal (GI) symptoms you’d like to discuss during your first appointment. You’ll be able to select your time in the next step.`
        )}
        animationDirection={animationDirection}
        showProgressBar={false}
        setAnimationDirection={setAnimationDirection}
      >
        <SymptomsTextArea
          native={native}
          appointmentTypeFromWebview={appointmentTypeFromWebview}
          formik={formik}
          handleSymptomsChange={handleSymptomsChange}
          enterSubmit={enterSubmit}
        />

        <OshiNextButton
          styles={btnStyles}
          containerStyles={{ marginTop: screenSize.isMobile ? '0' : '170px' }}
          buttonTitle='Continue'
          onClick={handleReserveAppt}
        />
      </AnimatedRoute>
      {!loggedIn?.isUserAccountBeingCreated && (
        <RightSide>
          <img
            src={ProviderVenn}
            width={511}
            alt='A three circle venn diagram'
          />
        </RightSide>
      )}
    </PageContainer>
  );
};

const PageContainer = styled.div`
  width: 100vw;
  height: 100vh;
`;

const RightSide = styled.div`
  position: absolute;
  right: 0;
  top: calc(50% - 279px);
  width: 40%;
  z-index: 5;
  @media (max-width: 1135px) {
    display: none;
  }
`;

const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

const BannerText = styled.span`
  display: inline-block;
  color: ${palette.navy500};
  font-family: 'Usual';
  font-size: 14px;
  font-weight: 600;
  line-height: 10px;
  vertical-align: middle;
`;

const ZeroCostContainer = styled.div`
  display: inline-block;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding-top: 4px;
  vertical-align: middle;
`;
const ZeroCostUnderline = styled.div`
  line-height: 0;
`;

const ZeroCost = styled.p`
  color: ${palette.navy500};
  font-family: 'Usual';
  font-size: 14px;
  font-weight: 700;
  line-height: 150%;
`;

const mapStateToProps = ({ uiReducer }) => ({
  isFetching: uiReducer.isFetching,
});
export default connect(mapStateToProps, {
  setIsFetching,
  setShowBanner,
  setShowRightSidedBannerVisibility,
})(withTracker(AppointmentConfirm, 'Signup - Add Symptoms - Page View'));
