import React from 'react';
import { isMobile } from 'react-device-detect';
import { useSpring } from 'react-spring';

import { palette } from '../theme/palette';
import { callAll } from '../utils/functionHelpers';
import {
  OSHI_FORM_INPUT_STATE as inputStates,
} from '../constants/ui';

const useInputType = ({ type, id, label, ...props }) => {
  const {
    error = false,
    disabled = false,
    autoFocus = false,
    tabIndex = -1,
    segmentLabel = null,
    autoFill = false,
    clearParentErrorOnFocus = null,
    inputLeaveEvent,
  } = props;

  const getInputStatus = () => {
    if (error) {
      return inputStates.ERROR;
    }

    if (disabled) {
      return inputStates.DISABLED;
    }

    if (autoFocus && !isMobile) {
      return inputStates.ACTIVE;
    }

    return inputStates.INACTIVE;
  };

  const [inputStatus, setInputStatus] = React.useState(getInputStatus());

  const onInputBlur = React.useCallback(() => {
    inputLeaveEvent(segmentLabel || `Need Segment Label ${label}`);
    if (!error) {
      setInputStatus(inputStates.INACTIVE);
    } else {
      setInputStatus(inputStates.ERROR);
    }
  }, [error, inputLeaveEvent, segmentLabel, label]);

  const onInputFocus = React.useCallback(() => {
    // basically cutting corners here to fit the
    // design needs
    clearParentErrorOnFocus && clearParentErrorOnFocus();
    setInputStatus(inputStates.ACTIVE);
  }, [clearParentErrorOnFocus]);

  const eventBasedProps = React.useMemo(() => {
    return Object.keys(props).reduce((acc, key) => {
      if (key.startsWith('on')) {
        if (key === 'onFocus' || key === 'onBlur') {
          return acc;
        }
        acc[key] = callAll(props[key]);
      }
      return acc;
    }, {});
  }, [props]);

  const accessibleProps = React.useMemo(() => {
    return Object.keys(props).reduce((acc, key) => {
      if (key === 'role' || key.startsWith('aria-')) {
        acc[key] = props[key];
      }
      return acc;
    }, {});
  }, [props]);

  const extraPropsByType = React.useMemo(() => {
    return {
      tabIndex: 0,
      ...(props?.mask ? { mask: props.mask } : {}),
      ...(typeof props?.maskPlaceholder === 'string'
        ? { maskPlaceholder: props.maskPlaceholder }
        : {}),
      ...(props?.maxChar > 0 && { maxLength: props?.maxChar }),
      ...accessibleProps,
      ...eventBasedProps,
      onFocus: callAll(onInputFocus, props?.onFocus),
      onBlur: callAll(onInputBlur, props?.onBlur),
    };
  }, [
    props,
    accessibleProps,
    eventBasedProps,
    onInputFocus,
    onInputBlur,
  ]);

  const titleAnime = useSpring({ color: palette.coolGray });

  const getDefaultProps = React.useCallback(() => {
    return {
      id,
      disabled,
      autoFocus: isMobile && autoFocus,
      tabIndex,
      autoComplete: autoFill ? 'on' : 'off',
      ...extraPropsByType,
    };
  }, [id, disabled, autoFill, autoFocus, tabIndex, extraPropsByType]);

  React.useEffect(() => {
    if (error) {
      setInputStatus(inputStates.ERROR);
    }
    if (!error && inputStatus === inputStates.ERROR) {
      setInputStatus(inputStates.ACTIVE);
    }
    // eslint-disable-next-line
  }, [error]);

  return {
    inputStatus,
    animatedTitle: titleAnime,
    getInputProps: getDefaultProps,
  };
};

export default useInputType;
