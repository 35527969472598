/* eslint-disable import/prefer-default-export */
/**
 * @function setViewportHeight
 * @description Set the viewport height to the actual height of the device.
 * This solves the problem of calculating the viewport height for mobile devices
 * according to the following article:
 * https://ilxanlar.medium.com/you-shouldnt-rely-on-css-100vh-and-here-s-why-1b4721e74487
 */
export const setViewportHeight = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

export const isAComponent = (component) => {
  return (
    typeof component === 'object' &&
    component?.$$typeof?.toString() === 'Symbol(react.element)'
  );
};

// Check if an element is focusable
export const isFocusable = (element) => {
  if (element.tabIndex < 0) {
    return false;
  }

  if (element.disabled) {
    return false;
  }

  switch (element.nodeName) {
    case 'A':
      return !!element.href && element.rel !== 'ignore';
    case 'INPUT':
      return element.type !== 'hidden';
    case 'BUTTON':
    case 'SELECT':
    case 'TEXTAREA':
      return true;
    default:
      return false;
  }
};

// Attempt to focus an element, returns true if successful
export const attemptFocus = (element) => {
  if (!isFocusable(element)) {
    return false;
  }
  try {
    element.focus();
  } catch (e) {}
  return document.activeElement === element;
};

// Find the first focusable element in a given element
export const findFirstFocusableElement = (element) => {
  for (let i = 0; i < element.childNodes.length; i++) {
    const child = element.childNodes[i];
    if (attemptFocus(child) || findFirstFocusableElement(child)) {
      return true;
    }
  }
  return false;
};

/**
 * SELECT WIDGET DOM EVENTS
 * Functions below is to handle UI events for select widget components
 */

// check if element is visible in browser view port
export const isElementInView = (element) => {
  const elementDimension = element.getBoundingClientRect();

  return (
    elementDimension.top >= 0 &&
    elementDimension.left >= 0 &&
    elementDimension.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    elementDimension.right <=
      (window.innerWidth || document.documentElement.clientWidth)
  );
};

// check if an element is currently scrollable
export const isElementScrollable = (element) => {
  return element && element.clientHeight < element.scrollHeight;
};

// ensure a given child element is within the parent's visible scroll area
// if the child is not visible, scroll the parent
export const maintainScrollVisibility = (activeElement, parent) => {
  const { offsetHeight, offsetTop } = activeElement;
  const { offsetHeight: parentOffsetHeight, scrollTop } = parent;

  const isAbove = offsetTop < scrollTop;
  const isBelow = offsetTop + offsetHeight > scrollTop + parentOffsetHeight;

  if (isAbove) {
    parent.scrollTo(0, offsetTop);
  } else if (isBelow) {
    parent.scrollTo(0, offsetTop - parentOffsetHeight + offsetHeight);
  }
};

// scroll into view the selected option in the popup list
export const scrollIntoPopupListOption = (optionEl, parentEl) => {
  const parentIsScrollable = parentEl && isElementScrollable(parentEl);

  parentIsScrollable && maintainScrollVisibility(optionEl, parentEl);

  !isElementInView(optionEl) &&
    optionEl.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
};

// set the parent element a custom attribute by found element index from its child list
export const setParentAttributeByFoundElementIndex = ({
  parentEl,
  listEl,
  clsToken,
  attribute,
  identifier,
}) => {
  const foundElIdx = Array.from(listEl).findIndex((el) =>
    el.classList.contains(clsToken)
  );
  foundElIdx >= 0 &&
    parentEl.setAttribute(attribute, `${identifier}${foundElIdx}`);
};

// switch focus to the next or previous option element in the popup list
// scroll into view the selected option and set the parent element a aria
// attribute by found element index from its child list
export const switchOptionElFocus = ({
  selectEl,
  listEl,
  optionEl,
  nextEl = null,
  identifier,
}) => {
  if (nextEl) {
    optionEl.classList.remove('focused');
    nextEl.classList.add('focused');
  }

  if (!nextEl) {
    optionEl.classList.add('focused');
    nextEl = optionEl;
  }

  setParentAttributeByFoundElementIndex({
    parentEl: selectEl,
    listEl: listEl.children,
    clsToken: 'focused',
    attribute: 'aria-activedescendant',
    identifier,
  });

  scrollIntoPopupListOption(nextEl, listEl);
};
