import React from 'react';
import styled from 'styled-components';
import { palette } from '../theme/palette';
import next from '../img/chevron_right.svg';
import previous from '../img/chevron_left.svg';
import { DatePickerLoader } from './SkeletonLoaders';
import {
  MONTHS,
  ONE_WEEK,
  WEEKDAYS,
  WEEKDAYS_FULL,
} from '../constants/calendar';
import {
  handleDayKeyboardActions,
  handleMonthKeyboardActions,
  WEEK_DAY_STATE,
} from '../utils/weekCalendar.domhelper';
import { formatDateWithIntl } from '../utils/date.utils.js';

const Day = ({ date, onClick, dayState }) => {
  const fontColor =
    dayState === WEEK_DAY_STATE.SELECTED ? palette.white : palette.navy;
  const dateValue = date.getDate();

  const backgroundColor = {
    disabled: palette.coolGray50,
    selected: palette.navy,
    available: palette.turquoise50,
  };

  return (
    <DayContainer
      id={`dayCell${dateValue}`}
      role='gridcell'
      className={`day-cell`}
      style={{
        backgroundColor: backgroundColor[dayState],
        cursor:
          dayState === WEEK_DAY_STATE.AVAILABLE ||
          dayState === WEEK_DAY_STATE.SELECTED
            ? 'pointer'
            : 'default',
      }}
      aria-selected={dayState === WEEK_DAY_STATE.SELECTED}
    >
      <DayBtn
        className={`day-btn ${dayState}`}
        onClick={dayState === WEEK_DAY_STATE.AVAILABLE ? onClick : null}
        aria-labelledby={`dayText${dateValue} dayNumber${dateValue} hiddenText${dateValue}`}
        tabIndex={dayState === WEEK_DAY_STATE.SELECTED ? 0 : -1}
      >
        <DayText aria-hidden='true' style={{ color: fontColor }}>
          {WEEKDAYS[date.getDay()]}
        </DayText>
        <HiddenText id={`dayText${dateValue}`} aria-hidden='true'>
          {WEEKDAYS_FULL[date.getDay()]}
        </HiddenText>
        <DayNumber
          id={`dayNumber${dateValue}`}
          aria-hidden='true'
          style={{ color: fontColor }}
        >
          {dateValue}
        </DayNumber>
        <HiddenText id={`hiddenText${dateValue}`} aria-hidden='true'>
          {dayState === WEEK_DAY_STATE.DISABLED ? 'Unselectable' : 'Selectable'}
        </HiddenText>
      </DayBtn>
    </DayContainer>
  );
};

const renderDays = ({
  selectedDate,
  selectedWeek,
  onDateChange,
  availableDates,
}) => {
  let days = [];

  while (days.length < ONE_WEEK) {
    const baseDate = new Date(selectedWeek);
    const date = new Date(baseDate.setDate(baseDate.getDate() + days.length));

    const formattedDate = formatDateWithIntl(date);

    const dayState = () => {
      if (!availableDates.includes(formattedDate))
        return WEEK_DAY_STATE.DISABLED;
      return date?.getTime() === selectedDate?.getTime()
        ? WEEK_DAY_STATE.SELECTED
        : WEEK_DAY_STATE.AVAILABLE;
    };

    days.push(
      <Day
        key={`day-${date.getDate()}`}
        date={date}
        onClick={() => onDateChange(date)}
        dayState={dayState()}
      />
    );
  }

  return days;
};

const weekLabelText = ({ selectedWeek }) => {
  const baseDate = new Date(selectedWeek);
  const endDate = new Date(baseDate.setDate(baseDate.getDate() + 7));

  return `${MONTHS[selectedWeek.getMonth()]} ${selectedWeek.getDate()} - ${
    MONTHS[endDate.getMonth()]
  } ${endDate.getDate()}`;
};

const OshiCalendar = ({
  lastLoadedDate,
  selectedWeek,
  selectedDate,
  onDateChange,
  availableDates,
  onNextWeek,
  onPrevWeek,
}) => {
  const weekCalRef = React.useRef(null);
  const isFirstWeek =
    selectedWeek.toString() === new Date(availableDates[0]).toString();
  const isLastWeek =
    availableDates.length > 0 &&
    availableDates.every((d) => new Date(d) < selectedWeek) &&
    !availableDates.includes(formatDateWithIntl(selectedWeek));
  const loadNextWeek = new Date(selectedWeek).getTime() >= lastLoadedDate;

  React.useEffect(() => {
    if (!selectedWeek || availableDates.length === 0) return;

    if (weekCalRef.current) {
      const focusableEl =
        weekCalRef.current.querySelector('#weekRow').firstChild.firstChild ||
        null;
      if (!focusableEl) {
        return;
      }
      focusableEl.classList.add(WEEK_DAY_STATE.FOCUSED);
      focusableEl.focus();
    }

    const handleKeyUpEvent = (event) => {
      event.preventDefault();
      const { key, code } = event;
      const focusedEl = document.activeElement;
      const focusedDayBtnEl =
        weekCalRef.current.querySelector(
          `.day-btn.${WEEK_DAY_STATE.FOCUSED}`
        ) ||
        weekCalRef.current.querySelector(`.day-btn.${WEEK_DAY_STATE.SELECTED}`);
      const prevBtnFocusedEl = weekCalRef.current.querySelector(
        `.prev-btn.${WEEK_DAY_STATE.FOCUSED}`
      );
      const nextBtnFocusedEl = weekCalRef.current.querySelector(
        `.next-btn.${WEEK_DAY_STATE.FOCUSED}`
      );

      if (!focusedDayBtnEl) return;

      let keyName = key;
      if (focusedDayBtnEl && prevBtnFocusedEl) {
        if (code === 'Space') {
          keyName = code;
        }
        handleMonthKeyboardActions(keyName, prevBtnFocusedEl, focusedDayBtnEl);
        return;
      }

      if (focusedDayBtnEl && nextBtnFocusedEl) {
        if (code === 'Space') {
          keyName = code;
        }
        handleMonthKeyboardActions(keyName, nextBtnFocusedEl, focusedDayBtnEl);
        return;
      }

      if (focusedDayBtnEl === focusedEl) {
        if (code === 'Space') {
          keyName = code;
        }
        handleDayKeyboardActions(keyName, focusedDayBtnEl);
      }
    };

    window.addEventListener('keyup', handleKeyUpEvent);
    return () => window.removeEventListener('keyup', handleKeyUpEvent);
  }, [selectedWeek, availableDates]);

  return (
    <CalendarContainer ref={weekCalRef} role='grid' aria-label='Week Calendar'>
      <WeekPickerContainer role='row'>
        <WeekPickerLabel role='gridcell' aria-colspan={ONE_WEEK - 1}>
          {weekLabelText({ selectedWeek })}
        </WeekPickerLabel>
        <WeekPickerButtons role='gridcell'>
          <WeekBtn
            role='button'
            id='prevWeekBtn'
            className={
              'prev-btn' + (isFirstWeek ? ` ${WEEK_DAY_STATE.DISABLED}` : '')
            }
            aria-label='Previous week'
            aria-disabled={isFirstWeek || loadNextWeek}
            tabIndex={-1}
            onClick={!isFirstWeek ? onPrevWeek : () => {}}
          >
            <ArrowIcon aria-hidden='true' src={previous} />
          </WeekBtn>
          <WeekBtn
            role='button'
            id='nextWeekBtn'
            className={
              'next-btn' + (isLastWeek ? ` ${WEEK_DAY_STATE.DISABLED}` : '')
            }
            aria-label='Next week'
            aria-disabled={isLastWeek || loadNextWeek}
            tabIndex={-1}
            onClick={!isLastWeek ? onNextWeek : () => {}}
          >
            <ArrowIcon aria-hidden='true' src={next} />
          </WeekBtn>
        </WeekPickerButtons>
      </WeekPickerContainer>
      <DayPickerContainer id='weekRow' role='row'>
        {loadNextWeek ? (
          <DatePickerLoader />
        ) : (
          renderDays({
            selectedDate,
            selectedWeek,
            onDateChange,
            availableDates,
          })
        )}
      </DayPickerContainer>
    </CalendarContainer>
  );
};

const WeekBtn = styled.button`
  background-color: transparent;
  border: none;
  opacity: 1;
  cursor: pointer;
  &.prev-btn.disabled,
  &.next-btn.disabled {
    opacity: 0.5;
    cursor: default;
  }
  &.prev-btn.focused,
  &.next-btn.focused {
    outline: -webkit-focus-ring-color double 1px;
    outline: rgb(0, 95, 204) double 1px;
    border-radius: 8px;
  }
`;

const ArrowIcon = styled.img`
  padding: 12px 16px;
`;

const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const DayPickerContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const DayContainer = styled.div`
  align-items: center;
  background-color: ${palette.turquoise50};
  border-radius: 8px;
  border: none;
  display: flex;
  flex-direction: column;
  height: 70px;
  justify-content: center;
  width: 100%;
  margin: 4px;

  &:last-child {
    margin-right: 0;
  }

  &:first-child {
    margin-left: 0;
  }

  @media only screen and (min-width: 769px) {
    margin: 0 6px;
  }
`;

const DayBtn = styled.button`
  background-color: transparent;
  border: none;
  height: 100%;
  width: inherit;
  cursor: inherit;
`;

const DayText = styled.h6`
  color: ${palette.navy};
  font-family: usual;
  font-size: 13px;
  font-weight: 300;
  margin: 3px 0;
`;

const DayNumber = styled.p`
  color: ${palette.navy};
  font-family: usual;
  font-size: 13px;
  font-weight: 600;
  margin: 3px 0;
`;

const HiddenText = styled.span`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;

const WeekPickerContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 12px;
`;

const WeekPickerLabel = styled.div`
  color: ${palette.navy};
  font-family: usual;
  font-weight: 600;
  line-height: 24px;
  font-size: 15px;
`;

const WeekPickerButtons = styled.div`
  align-self: end;
  display: flex;
  flex-direction: row;
`;

export default OshiCalendar;
