import React from 'react';
import styled from 'styled-components';

import OshiRadioButton from './OshiRadioButton';

/**
 * @function OshiRadioGroup
 * @description Custom and reusable UI component to group radio-button based UI components.
 * @param {React.ReactNode} children - Custom radio-button based UI components to be displayed within this group.
 * @param {styled.CSSObject} styles - Custom CSS object style to customize this component root element.
 * @returns {React.ReactElement}
 */
const OshiRadioGroup = ({
  data = [],
  renderLabel = null,
  selectedOption = null,
  onSelectOption,
  styles = {},
}) => {
  const radioGroupRef = React.useRef(null);

  React.useEffect(() => {
    if (data.length === 0) return;

    const handleKeyUp = (e) => {
      if (e.code === 'Space' || e.key === 'Enter') {
        const elFocused =
          Array.from(radioGroupRef.current?.children).find(
            (el) => el === document.activeElement
          ) || null;
        if (elFocused) {
          elFocused.click();
        }
      }
    };
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [data]);

  return (
    <RadioGroup role='radiogroup' style={styles} ref={radioGroupRef}>
      {data.map((it) => {
        const { item, children, value, id } = it;
        return (
          <OshiRadioButton
            key={id}
            {...it}
            onClick={() => onSelectOption(it)}
            checked={selectedOption && selectedOption === value}
          >
            {renderLabel
              ? renderLabel({ id, value, children, item })
              : children}
          </OshiRadioButton>
        );
      })}
    </RadioGroup>
  );
};

const RadioGroup = styled.fieldset`
  display: flex;
  flex-direction: column;
  white-space: pre-wrap;
  width: 100%;
`;

export default OshiRadioGroup;
