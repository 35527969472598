import React from 'react';
import styled from 'styled-components';
import Modal from './Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFaceConfused } from '@fortawesome/pro-regular-svg-icons';
import { palette } from '../theme/palette';
import Button from './Button';
import useWindowSize from '../hooks/useWindowSize';

const CardscanErrorModal = ({ onSkip, onRetry }) => {
  const { screenSize } = useWindowSize();
  return (
    <Modal
      containerStyle={{
        width: screenSize.width < 423 ? 'calc(100% - 48px)' : '375px',
        height: 'max-content',
      }}
      id='cardScanErrorModal'
      ariaRole='alertdialog'
      ariaLabelledBy='cardScanErrorModalTitle'
      ariaDescribedBy='cardScanErrorModalDesc'
      hideCloseButton
      onClose={onRetry}
    >
      <ModalContentContainer>
        <CircleIcon>
          <FontAwesomeIcon
            aria-hidden='true'
            icon={faFaceConfused}
            style={{ color: palette.turquoise200, fontSize: '32px' }}
          />
        </CircleIcon>
        <TitleText id='cardScanErrorModalTitle'>
          We seem to be having trouble scanning your card right now.
        </TitleText>
        <DescriptionText id='cardScanErrorModalDesc'>
          Uh oh, we were unable to scan your card. Would you like to try again?
        </DescriptionText>
        <Button
          type='button'
          onClick={onRetry}
          styles={{ width: 'fit-content', padding: '12px 24px' }}
        >
          Try Again
        </Button>
        <SkipButton type='button' onClick={onSkip}>
          Skip insurance information
        </SkipButton>
      </ModalContentContainer>
    </Modal>
  );
};

const ModalContentContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 24px;
  gap: 24px;
  @media (max-width: 768px) {
    gap: 16px;
  }
`;

const CircleIcon = styled.div`
  align-items: center;
  background-color: ${palette.turquoise50};
  border-radius: 36px;
  display: flex;
  height: 72px;
  justify-content: center;
  width: 72px;
`;

const TitleText = styled.h1`
  color: ${palette.navy500};
  font-family: Usual;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
`;

const DescriptionText = styled.p`
  color: ${palette.navy500};
  font-family: Usual;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
`;

const SkipButton = styled.button`
  background: transparent;
  border: none;
  color: ${palette.navy500};
  font-family: Usual;
  font-size: 15px;
  font-weight: 600;
  margin: 8px 0;
  text-align: center;
  text-decoration: underline;
  width: 100%;
`;

export default CardscanErrorModal;
