export const WEEKDAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const WEEKDAYS_FULL = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const ONE_WEEK = 7;
export const TWO_WEEK = 14;
export const SIX_WEEK = 42;

export const ONE_WEEK_MS = 604800000;
export const SIX_WEEK_MS = 3628800000;

export const FORTYEIGHT_HOURS = 172800000;
export const TWENTYFOUR_HOURS = 86400000;
