import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { palette } from '../theme/palette';
import { ApptOverviewPageSignOutBtnPressed } from '../actions/segment';

import OshiDownloadDisplay from '../components/OshiDownloadDisplay';
import AnimatedRoute from '../components/AnimatedRoute';
import { ROUTES } from '../constants/routes';
import withTracker from '../wrappers/withTracker';

const AppointmentOverview = (props) => {
  const history = useHistory();
  const location = useLocation();
  //Local storage variables
  const [animationDirection, setAnimationDirection] = useState('mount'); // mount, back, forward

  useEffect(() => {
    return () => {
      setAnimationDirection('unmount');
    };
  }, []);

  useEffect(() => {
    // If user skips insurance info flow then prevent it going back
    if (
      history.action === 'POP' &&
      (location.state?.skipInsurance ||
        location.pathname === ROUTES.INSURANCE_INFO)
    ) {
      history.goForward();
    }
    // eslint-disable-next-line
  }, [history, location]);

  return (
    <AnimatedRoute
      title='Success! Now, download the Oshi Health mobile app for your visit.'
      animationDirection={animationDirection}
      showProgressBar={false}
      setAnimationDirection={setAnimationDirection}
    >
      <DescriptionText>
        Your appointment will take place in the Oshi Health mobile app, so
        please make sure you download it. We look forward to seeing you at your
        scheduled appointment time!
      </DescriptionText>
      <PageContentContainer>
        <OshiDownloadDisplay />
        <VideoPlayerContainer>
          <VideoPlayer
            src='https://player.vimeo.com/video/736631362?h=726dda25e2&color=FFB59D&title=0&byline=0'
            frameborder='0'
            allow='autoplay; fullscreen;'
            allowfullscreen
          />
        </VideoPlayerContainer>
      </PageContentContainer>
    </AnimatedRoute>
  );
};

const PageContentContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
`;

const DescriptionText = styled.p`
  color: ${palette.coolGray};
  font-family: 'Usual';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 14px 0;
  width: 100%;
`;

const VideoPlayerContainer = styled.div`
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  @media only screen and (min-width: 769px) {
    width: 636px;
  }
`;

const VideoPlayer = styled.iframe`
  aspect-ratio: 16 / 9;
  width: 100vw;
  @media only screen and (min-width: 769px) {
    width: 80vw;
  }
`;

export default connect(null, {
  ApptOverviewPageSignOutBtnPressed,
})(withTracker(AppointmentOverview, 'Signup - App Download - Page View'));
