/* eslint-disable default-case */
import React from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';

import { palette } from '../theme/palette';
import { CheckMark } from '../assets/svgs';
import { inputLeaveEvent } from '../actions/segment';
import {
  OSHI_INPUT_TYPE as inputType,
  OSHI_FORM_INPUT_STATE as inputStates,
} from '../constants/ui';
import withPasswordInput from '../wrappers/withPasswordInput';
import useInputType from '../hooks/useInputType';

const AnimatedInput = animated(InputMask);

const OshiInput = React.forwardRef((props, ref) => {
  const {
    id,
    value,
    type = inputType.TEXT,
    label = null,
    showCheckMark = false,
    isValid = true,
    secondaryLabel = null,
    containerStyles = {},
    labelStyles = {},
    secondaryLabelStyles = {},
  } = props;

  const { animatedTitle, inputStatus, getInputProps } = useInputType(props);

  const getInputStyles = React.useCallback(() => {
    switch (inputStatus) {
      case inputStates.ACTIVE:
        return {
          backgroundColor: palette.background,
          borderBottom: '2px solid',
          borderBottomColor: palette.mediumTurqoise,
          caretColor: palette.darkPrimaryText,
          titleColor: palette.coolGray,
          color: palette.navy,
        };
      case inputStates.DISABLED:
        return {
          backgroundColor: palette.background,
          borderBottom: `1px solid`,
          borderBottomColor: palette.greyBorderColor,
          titleColor: palette.coolGray,
          color: palette.greyBorderColor,
          caretColor: palette.greyText,
          opacity: 0.7,
        };
      case inputStates.ERROR:
        return {
          backgroundColor: palette.background,
          caretColor: palette.errorBackground,
          borderBottom: '2px solid',
          borderBottomColor: palette.error,
          titleColor: palette.coolGray,
          color: palette.error,
        };
      default:
        break;
    }
    // default state here
    return {
      backgroundColor: palette.background,
      borderBottom: '2px solid',
      borderBottomColor: palette.greyBorderColor,
      titleColor: palette.coolGray,
      color: palette.coolGray,
      caretColor: palette.greyText,
    };
  }, [inputStatus]);

  const inputStyles = getInputStyles();
  const inputAnime = useSpring(inputStyles);

  const getCheckMark = React.useCallback(() => {
    if (showCheckMark && isValid && value.length > 0) {
      return <CheckMark color={palette.mediumTurqoise} />;
    }

    return null;
  }, [showCheckMark, isValid, value]);

  const renderInput = React.useCallback(() => {
    const defaultProps = getInputProps();
    const activeProps = {
      type,
      value,
      style: { ...inputAnime },
    };

    if (type === inputType.PASSWORD) {
      return (
        <PasswordInput
          {...defaultProps}
          {...activeProps}
          showCheckMark={showCheckMark}
          isValid={isValid}
          ref={ref}
        />
      );
    }

    return (
      <>
        <Input {...defaultProps} {...activeProps} ref={ref} />
        <IconContainer>{getCheckMark()}</IconContainer>
      </>
    );
    // eslint-disable-next-line
  }, [
    type,
    ref,
    showCheckMark,
    isValid,
    value,
    getInputProps,
    getCheckMark,
    inputAnime,
  ]);

  return (
    <Container style={{ ...containerStyles }}>
      {label ? (
        <Title htmlFor={id} style={{ ...animatedTitle, ...labelStyles }}>
          {label}
        </Title>
      ) : null}

      <InputContainer>{renderInput()}</InputContainer>

      {secondaryLabel ? (
        <SubTitle style={{ ...secondaryLabelStyles }}>
          {secondaryLabel}
        </SubTitle>
      ) : null}
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 5px;
  width: 100%;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
`;

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
`;

const IconContainer = styled.div`
  position: absolute;
  right: 10px;
  bottom: 20px;
  display: flex;
  align-items: center;
`;

const Input = styled(AnimatedInput)`
  display: flex;
  border: none;
  height: 41px;
  margin-bottom: 8px;
  padding-left: 8px;
  font-family: 'Usual';
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.03em;
`;

const Title = styled(animated.label)`
  margin-bottom: 8px;
  font-size: 14px;
  position: relative;
  font-family: 'Usual';
  line-height: 18px;
  letter-spacing: 0.05em;
`;

const SubTitle = styled.p`
  font-size: 13px;
  font-family: 'Usual';
  line-height: 19.5px;
  color: ${palette.coolGray500};
`;

const PasswordInput = withPasswordInput(Input);

export default connect(null, { inputLeaveEvent }, null, { forwardRef: true })(
  OshiInput
);
