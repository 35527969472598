/**
 * @function formatDateWithIntl
 * @description Format date with Intl.DateTimeFormat
 * @param {Date|String} date - Date object or date string
 * @returns 
 */
export const formatDateWithIntl = (date) => {
  const dateObj = date instanceof Date ? date : new Date(date);
  return new Intl.DateTimeFormat('en-US').format(dateObj);
};
