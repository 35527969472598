import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { palette } from '../theme/palette';
import { TimePickerLoader } from './SkeletonLoaders';
import useWindowSize from '../hooks/useWindowSize';
import {
  handleTimeSlotKeyboardActions,
  TIMESLOT_STATE,
} from '../utils/timeslot.domhelper';

const TimePicker = ({
  labels,
  onSelect,
  initialSelect,
  testId,
  lastLoadedDate,
  selectedWeek,
}) => {
  const [selected, setSelected] = useState(null);
  const timeslotRef = React.useRef(null);
  const currentWeekRef = React.useRef(selectedWeek);
  const { screenSize } = useWindowSize();

  const handleSlotClick = React.useCallback(
    (node) => {
      setSelected(node);
      onSelect(labels[node], node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [labels]
  );

  const slotsData = React.useMemo(() => {
    if (!labels || labels.length === 0) return null;

    let cellsPerRow = 0;
    const rows = [];

    if (screenSize.width < 430) {
      cellsPerRow = 3;
    } else if (screenSize.width < 769) {
      cellsPerRow = 5;
    } else {
      cellsPerRow = 6;
    }

    for (let i = 0; i < labels.length; i += cellsPerRow) {
      rows.push(labels.slice(i, i + cellsPerRow));
    }

    const cellWidth = `calc(100% / ${cellsPerRow} - 8px)`;

    return {
      rows,
      maxCellsPerRow: cellsPerRow,
      cellWidth,
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labels, screenSize.width]);

  const selectedSlot = React.useMemo(() => {
    if (selected === null || !slotsData) return null;

    const row = Math.floor(selected / slotsData.maxCellsPerRow);
    const cell = selected % slotsData.maxCellsPerRow;

    return { row, cell };
  }, [slotsData, selected]);

  useEffect(() => {
    slotsData && setSelected(null);
  }, [slotsData]);

  useEffect(() => {
    if (initialSelect >= 0) {
      setSelected(initialSelect);
    }
  }, [initialSelect]);

  React.useEffect(() => {
    if (
      !selectedWeek ||
      currentWeekRef.current !== selectedWeek ||
      !slotsData ||
      slotsData.rows.length === 0
    ) {
      currentWeekRef.current = selectedWeek;
      return;
    }

    if (
      timeslotRef.current &&
      currentWeekRef.current === selectedWeek &&
      !selectedSlot
    ) {
      const focusableEl =
        timeslotRef.current.querySelector(
          `.slot-btn.${TIMESLOT_STATE.SELECTED}`
        ) ||
        timeslotRef.current.querySelector('.slot-btn') ||
        null;
      if (!focusableEl) {
        return;
      }
      focusableEl.setAttribute('tabindex', 0);
      focusableEl.classList.add(TIMESLOT_STATE.FOCUSED);
    }

    const handleKeyUpEvent = (event) => {
      event.preventDefault();
      const { key, code } = event;
      const focusedEl = document.activeElement;
      const focusedSlotBtnEl =
        timeslotRef.current.querySelector(
          `.slot-btn.${TIMESLOT_STATE.FOCUSED}`
        ) ||
        timeslotRef.current.querySelector(
          `.slot-btn.${TIMESLOT_STATE.SELECTED}`
        );

      if (!focusedSlotBtnEl) return;

      let keyName = key;

      if (focusedSlotBtnEl === focusedEl) {
        if (code === 'Space') {
          keyName = code;
        }
        handleTimeSlotKeyboardActions(keyName, focusedSlotBtnEl);
      }
    };

    window.addEventListener('keyup', handleKeyUpEvent);
    return () => window.removeEventListener('keyup', handleKeyUpEvent);
  }, [selectedWeek, slotsData, selectedSlot]);

  if (new Date(selectedWeek).getTime() >= lastLoadedDate) {
    return <TimePickerLoader />;
  }

  return !slotsData || slotsData.rows.length === 0 ? (
    <NoTimesSection aria-live='polite' aria-describedby='noTimesAvailableId'>
      <NoTimesText
        type='subcopy'
        style={{ fontWeight: 300 }}
        id='noTimesAvailableId'
      >
        No appointments available.
      </NoTimesText>
    </NoTimesSection>
  ) : (
    <Container
      ref={timeslotRef}
      data-testid={testId || ''}
      role='grid'
      aria-label='Time slots'
    >
      {slotsData.rows.map((row, rowIndex) => (
        <Row key={rowIndex} role='row' className={`row-${rowIndex}`}>
          {row.map((appointmentDate, index, currentRow) => {
            const { available_from, provider_id } = appointmentDate;
            const uniqueId = available_from + provider_id + index;
            const isSelected =
              selectedSlot &&
              rowIndex === selectedSlot.row &&
              index === selectedSlot.cell;
            return (
              <Cell
                key={uniqueId}
                id={uniqueId}
                className={`slot-cell cell-${index}`}
                role='gridcell'
                highlighted={isSelected}
                style={{
                  width: slotsData.cellWidth,
                }}
                aria-selected={isSelected}
              >
                <SlotBtn
                  className={`slot-btn ${
                    isSelected
                      ? TIMESLOT_STATE.SELECTED
                      : TIMESLOT_STATE.AVAILABLE
                  }`}
                  role='button'
                  onClick={() =>
                    handleSlotClick(slotsData.maxCellsPerRow * rowIndex + index)
                  }
                  tabIndex={isSelected ? 0 : -1}
                >
                  {moment(appointmentDate.available_from).format('LT')}
                </SlotBtn>
              </Cell>
            );
          })}
        </Row>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  overflow-x: auto;
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  justify-content: flex-start;
`;

const Cell = styled.div`
  align-items: center;
  background-color: ${(props) =>
    props.highlighted ? palette.navy500 : palette.turquoise50};
  border-radius: 4px;
  color: ${(props) => (props.highlighted ? palette.navy50 : palette.navy500)};
  display: flex;
  font-family: Usual;
  font-size: 12px;
  font-weight: 600;
  height: 41px;
  justify-content: center;
  scroll-snap-align: start;
  cursor: pointer;
  width: 100%;
  margin-right: 8px;
  &:last-child {
    margin-right: 0;
  }
`;

const SlotBtn = styled.button`
  background: transparent;
  border: none;
  color: inherit;
  height: 100%;
  width: 100%;
`;

const NoTimesSection = styled.section`
  display: flex;
  @media (max-width: 768px) {
    padding: 0 2%;
  }
`;

const NoTimesText = styled.p`
  text-align: center;
  color: ${palette.coolGray};
  font-size: 12px;
  font-family: Usual;
  font-weight: 400;
  line-height: 131.7%;
  letter-spacing: 0.06em;
  @media (max-width: 768px) {
    padding: 0 2%;
  }
`;

export default React.memo(TimePicker);
