import React, { useState } from 'react';
import styled from 'styled-components';
import AnimatedRoute from '../components/AnimatedRoute';
import Typography from '../atoms/Typography';
import { palette } from '../theme/palette';
import OshiNextButton from '../components/OshiNextButton';

const SelfPay = () => {
  const [animationDirection, setAnimationDirection] = useState('mount');

  return (
    <AnimatedRoute
      title='Access our full team of GI specialists with self-pay.'
      animationDirection={animationDirection}
      setAnimationDirection={setAnimationDirection}
      showProgressBar={false}
    >
      <SubcopyContainer>
        <Typography
          type='paragraph'
          styles={{ color: palette.navy500, fontWeight: '400' }}
        >
          Based on the information you provided, it looks like Oshi Health is not yet in-network with your insurance. You can access Oshi Health with our self-pay option while we work to expand our network.
        </Typography>
      </SubcopyContainer>
      <LowerContainer>
        <OshiNextButton
          onClick={() => window.location.href=' https://oshihealth.com/paypervisit' }
          disabled={false }
          buttonTitle='Learn More'
        />
      </LowerContainer>
    </AnimatedRoute>
  );
}

const LowerContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin: 0 0 32px 0;
  height: 100%;
  width: 100%;
  @media only screen and (min-width: 1136px) {
    align-items: flex-start;
  }
`;

const SubcopyContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 0;
  letter-spacing: 0.04em;
  @media only screen and (min-width: 769px) {
    margin: 0 0px 42px 0px;
  }
`;

export default SelfPay;