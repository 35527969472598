import React from 'react';

import { localStorageGet } from '../utils/localStorageHelper';
import { switchOptionElFocus } from '../utils/domHelpers';

/**
 * @function useCombobox
 * @description Custom hook to handle combobox component logic and UI events.
 * @param {string} id - Unique identifier for the combobox component.
 * @param {Array<{label: string, value: any}>} data - List of options to be displayed as selectable options.
 * @param {string} placeholder - Custom placeholder text to be displayed as first unselectable option.
 * @param {string} storageKey - Custom key text to retrieve from Local Storage and display it as selectable option.
 * @param {(value: any) => void} onChange - UI event that gets called when an select option is selected and passes option value data.
 * @returns {{
 *  selectRef: React.Ref,
 *  popupListRef: React.Ref,
 *  expand: boolean,
 *  selectedItem: {label: string, value: any},
 *  handleListExpand: () => void,
 *  handleListItemClick: ({label: string, value: any}) => void,
 * }}
 */
const useCombobox = ({ id, data, placeholder, storageKey, onChange }) => {
  const labelRef = React.useRef(null);
  const selectRef = React.useRef(null);
  const popupListRef = React.useRef(null);
  const [popupTopPosition, setPopupTopPosition] = React.useState(0);
  const [expand, setExpand] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(() => {
    if (storageKey) {
      const data = localStorageGet(storageKey);
      if (data) {
        return { label: null, value: data[storageKey] };
      }
    }
    return { label: placeholder, value: null };
  });

  const handleListExpand = React.useCallback(() => {
    setExpand((v) => !v);
  }, []);

  const handleListItemClick = React.useCallback(
    (item) => {
      const { label, value } = item;
      onChange && onChange(item);
      setSelectedItem({
        label,
        value,
      });
      setExpand(false);
    },
    [onChange]
  );

  const handleListItemEnterKey = React.useCallback(
    (selectEl, optionEl) => {
      // Workaround to set text content up to select label element
      // when an option is selected by pressing Enter key without
      // waiting for React to change the text content through setState function
      selectEl.querySelector(`#${id}SelectedOptionLabel`).textContent =
        optionEl.textContent;
      // Handle select option click event
      optionEl.click();
      selectEl.setAttribute('aria-activedescendant', id);
      expand && setExpand(false);
    },
    [id, expand]
  );

  React.useEffect(() => {
    if (labelRef.current && selectRef.current) {
      setPopupTopPosition(
        labelRef.current.clientHeight + 8 + selectRef.current.clientHeight + 4
      );
    }
  }, []);

  React.useEffect(() => {
    if (data.length === 0) return;

    if (selectedItem.value && !selectedItem.label) {
      const foundItem = data.find((item) => item.value === selectedItem.value);
      if (foundItem) {
        setSelectedItem({ ...selectedItem, label: foundItem.label });
      }
    }
  }, [selectedItem, data]);

  React.useEffect(() => {
    if (data.length === 0) return;

    const handleKeyUp = (ev) => {
      const { key, code } = ev;
      const focusedEl = document.activeElement;
      const isSelectFocused = selectRef.current === focusedEl;
      const focusedOptionEl =
        popupListRef.current?.querySelector('.focused') || null;
      // Handle key events for select element
      if (isSelectFocused) {
        if (code === 'Space') {
          ev.preventDefault();
          ev.stopPropagation();
          !expand && setExpand(true);
        }
        switch (key) {
          case 'Enter':
          case 'ArrowDown':
          case 'ArrowUp':
            ev.preventDefault();
            ev.stopPropagation();
            !expand && setExpand(true);
            break;
          default:
            break;
        }
      }
      // Handle key events for option list
      if (isSelectFocused && expand) {
        if (code === 'Space') {
          ev.preventDefault();
          ev.stopPropagation();

          handleListItemEnterKey(selectRef.current, focusedOptionEl);
        }
        switch (key) {
          case 'Enter':
            ev.preventDefault();
            ev.stopPropagation();

            handleListItemEnterKey(selectRef.current, focusedOptionEl);
            break;
          case 'Escape':
            ev.preventDefault();
            ev.stopPropagation();

            focusedOptionEl.classList.remove('focused');
            expand && setExpand(false);
            break;
          case 'ArrowDown':
            ev.preventDefault();
            ev.stopPropagation();

            if (!focusedOptionEl) {
              switchOptionElFocus({
                selectEl: selectRef.current,
                listEl: popupListRef.current,
                optionEl: popupListRef.current.firstChild,
                nextEl: focusedOptionEl,
                identifier: id,
              });
              break;
            }

            if (focusedOptionEl.nextSibling) {
              switchOptionElFocus({
                selectEl: selectRef.current,
                listEl: popupListRef.current,
                optionEl: focusedOptionEl,
                nextEl: focusedOptionEl.nextSibling,
                identifier: id,
              });
            }

            break;
          case 'ArrowUp':
            ev.preventDefault();
            ev.stopPropagation();

            if (!focusedOptionEl) {
              switchOptionElFocus({
                selectEl: selectRef.current,
                listEl: popupListRef.current,
                optionEl: popupListRef.current.lastChild,
                nextEl: focusedOptionEl,
                identifier: id,
              });
              break;
            }

            if (focusedOptionEl.previousSibling) {
              switchOptionElFocus({
                selectEl: selectRef.current,
                listEl: popupListRef.current,
                optionEl: focusedOptionEl,
                nextEl: focusedOptionEl.previousSibling,
                identifier: id,
              });
            }

            break;
          default:
            break;
        }
      }
    };

    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keyup', handleKeyUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, id, expand]);

  return {
    labelRef,
    selectRef,
    popupListRef,
    expand,
    popupTopPosition,
    selectedItem,
    handleListExpand,
    handleListItemClick,
  };
};

export default useCombobox;
