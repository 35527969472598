/* eslint-disable no-plusplus */
import React from 'react';

import useDebounce from './useDebounce';
import useSearchableList, { defaultSearchFn } from './useSearchableList';
import { switchOptionElFocus } from '../utils/domHelpers';

/**
 * @function useAutocomplete
 * @module hooks/useAutocomplete
 * @description Custom hook which handles the autocomplete UI component logic.
 * It handles a set of UI events and customized logic to mimic the behavior of a native
 * UI autocomplete component.
 * @param {{
 *    searchFn: Function|Promise,
 *    data: Array,
 *    id: string,
 *    inputModel: FormikState,
 *    debounceInMillis: number,
 * }} params
 * @returns {{
 *    list: Array,
 *    inputRef: React.Ref,
 *    listRef: React.Ref,
 *    collapse: boolean,
 *    setRenderedList: Function,
 *    handleOnChange: Function,
 *    handleOnKeyDown: Function
 *    handleOnFocus: Function,
 *    handleOnItemClick: Function,
 * }}
 */
const useAutocomplete = ({
  searchFn = defaultSearchFn,
  data,
  id,
  inputModel,
  onSelectItem,
  debounceInMillis,
}) => {
  const inputRef = React.useRef(null);
  const listRef = React.useRef(null);
  const [collapse, setCollapse] = React.useState(true);
  const { debouncedValue } = useDebounce({
    value: inputModel.values[id],
    milliSeconds: debounceInMillis,
  });

  const { list, setResultFn } = useSearchableList({
    searchFn,
    data,
  });

  const handleOnChange = React.useCallback(
    (ev) => {
      const focusedOptionEl =
        listRef.current?.querySelector('.focused') || null;
      if (focusedOptionEl) {
        focusedOptionEl.classList.remove('focused');
      }
      collapse && setCollapse(false);
    },
    [collapse]
  );

  const handleOnFocus = React.useCallback((ev) => {
    const { value } = ev.target;
    const end = value.length;
    ev.target.selectionStart = end;
    ev.target.selectionEnd = end;
    ev.target.setSelectionRange(end, end);
  }, []);

  const handleOnItemClick = React.useCallback(
    (ev) => {
      onSelectItem && onSelectItem(ev);
      !collapse && setCollapse(true);
    },
    [collapse, onSelectItem]
  );

  React.useEffect(() => {
    if (list.length === 0) return;

    const handleKeyUp = (ev) => {
      const { key } = ev;
      const focusedEl = document.activeElement;
      const isInputFocused = focusedEl === inputRef.current;
      const focusedOptionEl =
        listRef.current?.querySelector('.focused') || null;

      if (isInputFocused && list.length > 0) {
        switch (key) {
          case 'Enter': {
            ev.preventDefault();
            ev.stopPropagation();
            if (focusedOptionEl) {
              focusedOptionEl.classList.remove('focused');
              focusedOptionEl.classList.add('selected');
              focusedOptionEl.click();
              inputRef.current.setAttribute('aria-activedescendant', id);
            }
            break;
          }
          case 'Escape':
            ev.preventDefault();
            ev.stopPropagation();

            focusedOptionEl && focusedOptionEl.classList.remove('focused');

            !collapse && setCollapse(true);

            if (collapse && inputRef.current.value.length > 0) {
              inputRef.current.value = '';
            }
            break;
          case 'ArrowDown': {
            ev.preventDefault();
            ev.stopPropagation();

            collapse && setCollapse(false);

            if (!focusedOptionEl) {
              switchOptionElFocus({
                selectEl: inputRef.current,
                listEl: listRef.current,
                optionEl: listRef.current.firstChild,
                nextEl: focusedOptionEl,
                identifier: id,
              });
              break;
            }

            if (focusedOptionEl.nextSibling) {
              switchOptionElFocus({
                selectEl: inputRef.current,
                listEl: listRef.current,
                optionEl: focusedOptionEl,
                nextEl: focusedOptionEl.nextSibling,
                identifier: id,
              });
            }
            break;
          }
          case 'ArrowUp': {
            ev.preventDefault();
            ev.stopPropagation();

            collapse && setCollapse(false);

            inputRef.current.setSelectionRange(
              inputRef.current.value.length,
              inputRef.current.value.length
            );

            if (!focusedOptionEl) {
              switchOptionElFocus({
                selectEl: inputRef.current,
                listEl: listRef.current,
                optionEl: listRef.current.lastChild,
                nextEl: focusedOptionEl,
                identifier: id,
              });
              break;
            }

            if (focusedOptionEl.previousSibling) {
              switchOptionElFocus({
                selectEl: inputRef.current,
                listEl: listRef.current,
                optionEl: focusedOptionEl,
                nextEl: focusedOptionEl.previousSibling,
                identifier: id,
              });
            }
            break;
          }
          default:
            break;
        }
      }
    };

    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [list, id, collapse]);

  // Search debouced value on every input change
  React.useLayoutEffect(() => {
    setResultFn(debouncedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return {
    list,
    inputRef,
    listRef,
    collapse,
    handleOnItemClick,
    handleOnChange,
    handleOnFocus,
  };
};

export default useAutocomplete;
