import React from 'react';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';

import CheckCircle from '../atoms/CheckCircle';
import { RadioCheckMark } from '../assets/svgs';
import { palette } from '../theme/palette';
import { OSHI_INPUT_TYPE } from '../constants/ui';

/**
 * @function CheckableBox
 * @description Generic and reusable UI checkable box component that behaves with fundamental functionality
 * of either a radio button or a checkbox.
 * @param {string} id - Name text to handle linking UI form functionality with this component.
 * @param {string} type - Type text to handle radio-button or checkbox UI form functionality.
 * @param {React.ReactNode} label - Text or JSX element to be displayed as label for this component.
 * @param {string} shape - Check icon shape to determine how it will be displayed.
 * @param {string} value - Selected radio button value represented as text
 * @param {(value: any): void} onClick - UI event callback that gets called when this component is clicked and passes its value as argument.
 * @param {boolean} checked - Boolean value to verify is this component was checked or not.
 * @param {number} tabIndex - Number value to handle focusability of this component.
 * @param {boolean} hasError - Boolean value to verify if this component has an error from form validation
 * @param {boolean} hasContainerBorder - Boolean value to verify if this component container has a animated-colored border or not
 * @param {styled.CSSObject} containerStyles - Custom CSS object style to customize this component root element.
 * @param {styled.CSSObject} labelContainerStyles - Custom CSS object style to customize this component label element.
 * @returns {React.ReactElement}
 */
const CheckableBox = React.forwardRef(({
  id,
  type = OSHI_INPUT_TYPE.RADIO,
  label,
  shape = 'rounded',
  value,
  onClick,
  checked = false,
  tabIndex = 0,
  hasError = false,
  hasContainerBorder = false,
  containerStyles = {},
  labelContainerStyles = {},
}, ref) => {
  const animatedColor = useSpring({
    borderColor: checked ? palette.mediumTurqoise : palette.coolGray200,
  });
  return (
    <CheckableContainer
      id={value}
      role={type}
      tabIndex={tabIndex}
      aria-labelledby={id}
      aria-checked={checked}
      onClick={onClick}
      ref={ref}
      style={{
        ...containerStyles,
        ...(hasContainerBorder
          ? { ...animatedColor }
          : { border: 'none', borderRadius: 0 }),
      }}
    >
      <CheckCircleContainer>
        <CheckCircle
          componentKey={id}
          onClick={onClick}
          borderColor={hasError ? palette.error : palette.coolGray200}
          nonFilledColor={palette.white}
          isFilled={checked}
          isRounded={shape === 'rounded'}
        >
          <RadioCheckMark />
        </CheckCircle>
      </CheckCircleContainer>
      <LabelContainer style={labelContainerStyles}>
        <>
          {typeof label === 'string' ? (
            <Title
              htmlFor={id}
              styles={{ color: hasError ? palette.error : null }}
            >
              {label}
            </Title>
          ) : (
            <>
              {label}
              <HiddenLabel id={id}>{value}</HiddenLabel>
            </>
          )}
        </>
      </LabelContainer>
    </CheckableContainer>
  );
});

const CheckableContainer = styled(animated.div)`
  border: 1px solid;
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 16px;
  margin: 5px 0;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

const LabelContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  cursor: pointer;
  justify-content: center;
`;

const Title = styled.label`
  font-family: 'Usual';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.03em;
  color: ${palette.coolGray};
  cursor: pointer;
`;

const HiddenLabel = styled.label`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;

const CheckCircleContainer = styled.div`
  padding: 0 20px 0 0;
`;

export default CheckableBox;
