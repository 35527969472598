export const PATIENT_INSURANCE_STATUS = {
  NONE: 'none',
  FAILED: 'failed',
  VALID: 'valid',
  SKIPPED: 'skipped',
};

export const PATIENT_ONBOARDING_STATUS = {
  NONE: 'none',
  PENDING: 'pending',
  FINISHED: 'finished',
};
