import React from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';

import { ChevronDownIcon as Icon } from '../assets/svgs';
import { palette } from '../theme/palette';

/**
 * @function OshiAccordion
 * @description Generic and reusable UI accordion component that follows accordion-like UI features and ARIA guidelines.
 * Could be used to display a header and a panel that can be expanded or collapsed.
 * Accordion header can be customized with a custom UI component or render with default UI component.
 * @param {string} id - Unique identifier for this accordion component.
 * @param {string} headerHeadline - Text to be displayed as the header of this accordion component.
 * @param {function} renderHeader - Callback function to render the header of this accordion component as custom UI component.
 * @param {boolean} isExpanded - Boolean value to verify if this accordion component is expanded or not.
 * @param {function} onExpand - Callback function to handle the expand/collapse event of this accordion component.
 * @param {function} onPanelScroll - Callback function to handle the scroll event of the panel content when this accordion component is expanded.
 * @param {React.ReactNode} children - JSX element to be displayed as the accordion panel content when this component is expanded.
 * @param {boolean} error - Boolean value to verify if this accordion component has an error from form validation.
 * @returns {React.ReactElement}
 */
export default function OshiAccordion({
  id,
  headerHeadline,
  renderHeader = null,
  isExpanded = false,
  onExpand = null,
  children,
  onPanelScroll = null,
  error = false,
}) {
  const headerBtnRef = React.useRef(null);

  const animatedExpand = useSpring({
    config: { mass: 1, tension: 180, friction: 20 },
    height: isExpanded ? '280px' : '60px',
  });

  const animatedContainerBackground = useSpring({
    backgroundColor: error ? palette.errorBackground : palette.background,
    borderColor: error ? palette.error : '#E0E3E5',
  });

  const animatedIcon = useSpring({
    transform: isExpanded ? 'rotate(-180deg)' : 'rotate(0deg)',
  });

  const handleOnExpandAccordion = React.useCallback(
    (event) => {
      onExpand && onExpand();
    },
    [onExpand]
  );

  const handleOnPanelScroll = React.useCallback(
    (event) => {
      const bottom =
        event.target.scrollHeight - event.target.scrollTop ===
        event.target.clientHeight;
      if (!bottom) return;

      onPanelScroll && onPanelScroll();
    },
    [onPanelScroll]
  );

  const renderAccordionHeader = React.useCallback(() => {
    if (renderHeader) {
      return (
        <CustomHeader>
          {renderHeader({ id, headerHeadline, error })}
          <IconContainer
            role='button'
            ref={headerBtnRef}
            tabIndex={0}
            aria-label={`Expand ${headerHeadline}`}
            aria-expanded={isExpanded}
            aria-controls={`${id}Panel`}
            onClick={handleOnExpandAccordion}
            style={animatedIcon}
          >
            <Icon color={error && palette.error} />
          </IconContainer>
        </CustomHeader>
      );
    }
    return (
      <AccordionHeaderBtn
        id={id}
        role='button'
        tabIndex={0}
        ref={headerBtnRef}
        onClick={handleOnExpandAccordion}
        aria-label={headerHeadline}
        aria-expanded={isExpanded}
        aria-controls={`${id}Panel`}
      >
        <AccordionHeaderHeadline
          style={{ color: error ? palette.error : null }}
        >
          {headerHeadline}
        </AccordionHeaderHeadline>
        <IconContainer style={{ ...animatedIcon, height: 'auto' }}>
          <Icon color={error && palette.error} />
        </IconContainer>
      </AccordionHeaderBtn>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderHeader, id, animatedIcon, error, isExpanded, headerHeadline]);

  React.useEffect(() => {
    if (!renderHeader && !headerBtnRef.current) return;

    const el = headerBtnRef.current;
    const handleKeyPress = (e) => {
      if (e.code === 'Space') {
        const elFocused = el && el === document.activeElement;
        if (elFocused) {
          e.preventDefault();
          el.click();
        }
      }
    };
    window.addEventListener('keypress', handleKeyPress);
    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, [renderHeader]);

  return (
    <AccordionContainer
      style={{ ...animatedExpand, ...animatedContainerBackground }}
    >
      <AccordionHeader>{renderAccordionHeader()}</AccordionHeader>
      {isExpanded ? (
        <AccordionPanel
          id={`${id}Panel`}
          role='region'
          aria-labelledby={id}
          onScroll={handleOnPanelScroll}
        >
          {children}
        </AccordionPanel>
      ) : null}
    </AccordionContainer>
  );
}

const AccordionContainer = styled(animated.div)`
  width: 100%;
  border: 1px solid;
  border-radius: 8px;
  display: flex;
  position: relative;
  margin: 5px 0;
`;

const AccordionHeader = styled.h3`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  width: 100%;
  justify-content: center;
`;

const AccordionHeaderHeadline = styled.p`
  font-family: 'Usual';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.03em;
  margin: 0;
  color: ${palette.coolGray500};
  cursor: pointer;
`;

const AccordionHeaderBtn = styled.div`
  padding-left: 20px;
  cursor: pointer;
  height: 100%;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  display: flex;
  box-sizing: border-box;
`;

const CustomHeader = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-between;
`;

const IconContainer = styled(animated.div)`
  padding: 0 20px;
  cursor: pointer;
  height: 100%;
  align-items: center;
  display: flex;
`;

const AccordionPanel = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
  padding: 0 15px 15px;
  color: ${palette.coolGray500};
  font-family: 'Usual';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.04em;
  margin-top: 60px;
`;
