import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { setShowBanner, setPatientProfile } from '../actions';
import usePatientAPI from '../hooks/usePatientAPI';
import { palette } from '../theme/palette';
import getUrlParameter from '../utils/getUrlParameter';
import { UserContext } from '../utils/context';
import useAuthPatient from '../hooks/useAuthPatient';
import { localStorageGet } from '../utils/localStorageHelper';
import useWindowSize from '../hooks/useWindowSize';

function SignedInAs({
  styles,
  additionalText = '',
  setShowBanner,
  setPatientProfile,
  signOutEvent,
}) {
  const history = useHistory();
  const native = getUrlParameter('platform', history) === 'native';
  const { signOut } = usePatientAPI();
  const { setIsLoggedIn } = React.useContext(UserContext);
  const { authUser } = useAuthPatient();
  const { partner } = localStorageGet('partner');
  const { screenSize } = useWindowSize();

  if (!authUser || native) return null;

  const mainText = `Signed in as ${authUser.attributes.name}. `;
  const linkText = 'Not you?';

  async function handleSignOut() {
    try {
      signOutEvent && signOutEvent();
      await signOut();
      localStorage.clear();
      setIsLoggedIn(false);
      setPatientProfile(false);
      history.replace(`/name${partner ? `/${partner}` : ''}`);
      setShowBanner({
        sentiment: 'positive',
        text: `You’ve successfully signed out.`,
      });
    } catch (error) {
      throw new Error('signOut error', { cause: error });
    }
  }

  return (
    <Container style={styles}>
      <CopyTextContainer hasadditionaltext={Boolean(additionalText)}>
        {!screenSize.isMobile ? (
          <>
            {additionalText && (
              <AdditionalText>{`${additionalText} `}</AdditionalText>
            )}
            <Text>
              {mainText}
              <LinkBtn
                type='button'
                onClick={handleSignOut}
              >
                {linkText}
              </LinkBtn>
            </Text>
          </>
        ) : (
          <Text>
            {`${additionalText} ` || ''}
            {mainText}
            <LinkBtn
              type='button'
              onClick={handleSignOut}
            >
              {linkText}
            </LinkBtn>
          </Text>
        )}
      </CopyTextContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: flex-end;
`;

const CopyTextContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: flex-end;
  @media only screen and (min-width: 769px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Text = styled.div`
  display: inline-block;
  font-size: 14px;
  color: ${palette.coolGray500};
  font-family: 'Usual', serif;
  line-height: 21px;
`;

const AdditionalText = styled.p`
  display: inline-flex;
  color: ${palette.coolGray500};
  font-family: 'Usual', serif;
  font-size: 14px;
  line-height: 21px;
`;

const LinkBtn = styled.button`
  display: inline-block;
  color: ${palette.navy500};
  height: auto;
  border: none;
  padding: 0;
  background: transparent;
  font-family: 'Usual', serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.04em;
  text-decoration: underline;
  line-height: 21px;
`;
export default connect(null, { setShowBanner, setPatientProfile })(SignedInAs);
