import React from 'react';
import styled from 'styled-components';

import useAutocomplete from '../hooks/useAutocomplete';
import OshiACResultList from './OshiACResultList';
import OshiInput from './OshiInput';
import { callAll } from '../utils/functionHelpers';

/**
 * @function OshiAutocomplete
 * @module components/OshiAutocomplete
 * @description Custom and reusable autocomplete UI component which handles basic autocomplete functionality.
 * It handles a set of UI mouse + keyboard events, customized UI logic and a search criteria function to comply
 * with the autocomplete expected behavior.
 * The list of items must be provided and formatted as an array of objects with the following shape:
 * Array<{value: string, label: string}> where value is the unique identifier of the item and label to show the item text.
 * It reuses OshiInput component to avoid duplicating code and keep consistency.
 * @param {{
 *    id: string,
 *    label: string,
 *    value: string,
 *    onChange: Function,
 *    extraProps: OshiInput<Props>,
 *    data: Array<{value: string, label: string}>
 *    searchCriteria: Function|Promise,
 *    inputModel: FormikState,
 * }} params
 * @returns {JSX.Element}
 */
const OshiAutocomplete = ({
  id,
  label,
  value,
  onChange,
  onSelectItem,
  extraProps,
  data,
  searchCriteria,
  inputModel,
}) => {
  const {
    list,
    inputRef,
    listRef,
    collapse,
    handleOnChange,
    handleOnFocus,
    handleOnItemClick,
  } = useAutocomplete({
    data,
    id,
    searchFn: searchCriteria,
    inputModel,
    onSelectItem,
    debounceInMillis: 250,
  });
  return (
    <Container>
      <Container>
        <OshiInput
          {...extraProps}
          id={id}
          label={label}
          ref={inputRef}
          value={value}
          onChange={callAll(handleOnChange, onChange)}
          onFocus={handleOnFocus}
          role='combobox'
          aria-autocomplete='list'
          aria-expanded={!collapse && list.length > 0}
          aria-controls={`${id}ListBox`}
          tabIndex='0'
        />
      </Container>
      <Container>
        {!collapse && list.length > 0 && (
          <OshiACResultList
            list={list}
            ref={listRef}
            parentId={id}
            onItemClick={handleOnItemClick}
            role='listbox'
            id={`${id}ListBox`}
            aria-label={label}
            tabIndex='-1'
          />
        )}
      </Container>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

export default OshiAutocomplete;
