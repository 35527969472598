import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import OshiInput from '../components/OshiInput';
import AnimatedRoute from '../components/AnimatedRoute';
import OshiLink from '../components/OshiLink';
import regex from '../utils/regex';
import getRepeatItemArray from '../utils/getRepeatItemArray';
import Typography from '../atoms/Typography';
import { palette } from '../theme/palette';
import { LIST_OF_CURRENT_PARTNERS } from '../constants/subscriptionStatus';
import {
  pageViewEvent,
  userFullNameSubmitEvent,
  NamePageSigninBtnPressed,
} from '../actions/segment';
import { setShowBanner, setIsNameRoute } from '../actions';
import useNamePageUILogic from '../hooks/useNamePageUILogic';
import OshiNextButton from '../components/OshiNextButton';
import { localStorageGet, localStorageSave, localStorageRemove } from '../utils/localStorageHelper';

const title = 'Join Oshi Health.';
const subHeader =
  'Sign up and book your first visit in 3 minutes. Enter your name to get started.';

function Name({
  pageViewEvent: pageViewUIEvent,
  userFullNameSubmitEvent: userFullNameSubmit,
  setShowBanner,
  NamePageSigninBtnPressed,
  setIsNameRoute,
}) {
  const { partnerName } = useParams();
  const history = useHistory();
  const {
    nextRoute,
    formik,
    showErrors,
    animationDirection,
    setAnimationDirection,
    getTitle,
    clearParentErrorOnFocus,
    handleOnKeyPress,
    handleOnSubmit,
  } = useNamePageUILogic(
    LIST_OF_CURRENT_PARTNERS.includes(partnerName) ? partnerName : null,
    pageViewUIEvent,
    userFullNameSubmit,
    setShowBanner
  );

  useEffect(() => {
    if (partnerName !== localStorageGet('partner')?.partner || !partnerName) {
      localStorageSave('partner', { partner: null });
      localStorageRemove('selectedPartner');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerName])

  useEffect(() => {
    setIsNameRoute(true);
    return () => {
      setShowBanner(false);
      setIsNameRoute(false);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <AnimatedRoute
      nextRoute={nextRoute}
      title={getTitle(title, subHeader)}
      animationDirection={animationDirection}
      setAnimationDirection={setAnimationDirection}
    >
      <Form onKeyDown={handleOnKeyPress}>
        <OshiInput
          id='firstName'
          autoFocus
          type='text'
          label='First Name'
          maskPlaceholder=''
          onChange={formik.handleChange}
          value={formik.values.firstName}
          clearParentErrorOnFocus={clearParentErrorOnFocus}
          segmentLabel='Sign Up - First Name'
          mask={getRepeatItemArray(regex.name, 50)}
          error={showErrors && formik.errors.firstName === showErrors}
        />

        <OshiInput
          id='lastName'
          type='text'
          label='Last Name'
          maskPlaceholder=''
          segmentLabel='Sign Up - Last Name'
          onChange={formik.handleChange}
          value={formik.values.lastName}
          clearParentErrorOnFocus={clearParentErrorOnFocus}
          mask={getRepeatItemArray(regex.name, 50)}
          error={showErrors && formik.errors.lastName === showErrors}
        />

        {showErrors && (
          <ErrorContainer>
            <Typography styles={{ color: palette.error }}>
              {showErrors}
            </Typography>
          </ErrorContainer>
        )}
      </Form>

      <OshiNextButton
        styles={{ width: '100%' }}
        buttonContainerStyles={{ alignItems: 'center' }}
        buttonTitle={'Continue'}
        disabled={!formik.dirty && !formik.initialStatus.existingValues}
        onClick={handleOnSubmit}
      >
        <SignInContainer>
          <AccountText>Already have an account? </AccountText>
          <OshiLink
            styles={{ minWidth: 0, color: palette.navy500 }}
            buttonTitle='Sign in.'
            onClick={() => {
              NamePageSigninBtnPressed();
              history.push('/signin');
            }}
          />
        </SignInContainer>
      </OshiNextButton>
    </AnimatedRoute>
  );
}

const Form = styled.form`
  width: 100%;
  flex: 1;
  @media only screen and (min-width: 429px) {
    flex: 0;
  }
`;

const ErrorContainer = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
`;

const SignInContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  font-family: 'Usual', serif;
  color: ${palette.coolGray500};
  flex: 0;
  @media only screen and (min-width: 429px) {
    flex: 1;
    align-items: flex-end;
  }
`;

const AccountText = styled.div`
  align-items: center;
  display: flex;
  height: 41px;
`;

export default connect(null, {
  pageViewEvent,
  userFullNameSubmitEvent,
  NamePageSigninBtnPressed,
  setShowBanner,
  setIsNameRoute,
})(Name);
