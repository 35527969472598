import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { API } from 'aws-amplify';

import TakePhotoButton from '../img/takePhotoButton.svg';
import UploadPhotoButton from '../img/uploadPhotoButton.svg';
import AnimatedRoute from '../components/AnimatedRoute';
import { palette } from '../theme/palette';
import { ROUTES } from '../constants/routes';
import SkipCardscanModal from '../components/SkipCardscanModal';
import usePatientProfile from '../hooks/usePatientProfile';
import { appointmentTypes } from '../constants';
import { APPOINTMENT_ERROR } from '../constants/appointment';
import { localStorageGet } from '../utils/localStorageHelper';
import useBookApptFlowSegmentTrackerOnPageView from '../hooks/useBookApptFlowSegmentTrackerOnPageView';
import { trackOnLoadedScreenEvent } from '../actions/segment';
import {
  TrackInsuranceInformation,
  InsuranceCardScanButtonPressed,
  InsuranceCardUploadButtonPressed,
} from '../actions/segment';
import { setIsFetching, setShowBanner } from '../actions';
import { AppointmentContext } from '../utils/context';
import {
  PATIENT_INSURANCE_STATUS,
  PATIENT_ONBOARDING_STATUS,
} from '../constants/patient';
import usePatientAPI from '../hooks/usePatientAPI';

const InsuranceInformationRoute = ({
  TrackInsuranceInformation,
  InsuranceCardScanButtonPressed,
  InsuranceCardUploadButtonPressed,
  setShowBanner,
  setIsFetching,
  trackOnLoadedScreenEvent,
}) => {
  const [animationDirection, setAnimationDirection] = useState('mount');
  const [appointment, setAppointment] = useContext(AppointmentContext);
  const dispatch = useDispatch();
  const [showSkipModal, setShowSkipModal] = useState(false);
  const { appointmentType } = localStorageGet('appointmentType');
  const history = useHistory();
  const reservedAppointment = localStorageGet('reservedAppointment');
  const { patientProfile } = usePatientProfile();
  const { customPatientUpdate } = usePatientAPI();
  const { provider_id, to_date_time, from_date_time, patient_cognito_id, utm } =
    reservedAppointment;

  async function processAppointment() {
    try {
      //block for the cashpay users - does not apply to optum
      // it is here to ensure the user is successfully subscribed to stripe
      if (patientProfile.subscription_status !== 'Optum') {
        const userSubscriptionStatus = await API.get(
          'oshiAPI',
          `/users/subscribed?email=${encodeURIComponent(patientProfile.email)}`
        );

        //Why are we directing user back to book page if their status is unsubscribed?
        // we are directing the user back to the book page for them to go through the flow again in case the subscription was not successful on the first try
        if (!userSubscriptionStatus.subscribed) {
          // TODO: remove this when not doing cashpay
          // we shouldn't hit this case tho since users
          // really can't go through cashpay
          history.replace('first-appointment-book');
          setAnimationDirection('unmount');
          return setIsFetching(false);
        }
      }

      // check to see if we have the details for
      // an appointment and we then reserve it  else
      // send them back to the cal
      if (reservedAppointment) {
        await API.post(
          'oshiAPI',
          `/users/${patientProfile.cognito_id}/appointments`,
          {
            body: {
              provider_id,
              from_date_time,
              to_date_time,
              patient_cognito_id,
              appointment_type: 'onboarding',
              ...(utm
                ? {
                    utm,
                  }
                : {}),
            },
          }
        );

        triggerSuccessfullBook();
        return setIsFetching(false);
      }
      history.replace(ROUTES.FIRST_APPT_BOOK);
      return setIsFetching(false);
    } catch (error) {
      // trigger error booking onboarding appointment
      // and send them to the first appointment book page
      handleErrorAndSendToBooking();
      setIsFetching(false);
    }
  }

  function triggerSuccessfullBook() {
    setShowBanner(false);
    // clear context
    setAppointment();
  }

  const handleErrorAndSendToBooking = () => {
    setShowBanner({
      sentiment: 'negative',
      text: `We're sorry, this time is no longer available. Please select a different appointment time.`,
    });
    dispatch({
      type: appointmentTypes.APPOINTMENT_ERROR,
      payload: APPOINTMENT_ERROR.TIMESLOT_TAKEN,
    });
    setAnimationDirection('unmount');
    history.replace(ROUTES.FIRST_APPT_BOOK);
  };

  const handleCardScanClick = () => {
    InsuranceCardScanButtonPressed();
    setAnimationDirection('unmount');
    history.push(ROUTES.INSURANCE_CARD_SCAN);
  };

  const handleCardUploadClick = () => {
    InsuranceCardUploadButtonPressed();
    setAnimationDirection('unmount');
    history.push(ROUTES.INSURANCE_CARD_UPLOAD);
  };

  const handleShareInsuranceLaterClick = async () => {
    setShowSkipModal(false);
    try {
      setIsFetching(true);
      await customPatientUpdate({
        insurance_card_status: PATIENT_INSURANCE_STATUS.SKIPPED,
      });
      setIsFetching(false);
      history.replace(ROUTES.FIRST_APPT_OVERVIEW, { skipInsurance: true });
    } catch (error) {
      console.log('Error updating insurance card status to skipped', error);
      setIsFetching(false);
    }
  };

  useBookApptFlowSegmentTrackerOnPageView(
    appointmentType,
    trackOnLoadedScreenEvent
  );

  useLayoutEffect(() => {
    TrackInsuranceInformation();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    setIsFetching(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => {
      // eslint-disable-next-line no-undef

      if (patientProfile) {
        if (
          patientProfile.insurance_card_status === PATIENT_INSURANCE_STATUS.SKIPPED ||
          patientProfile.insurance_card_status === PATIENT_INSURANCE_STATUS.VALID  
        ) {
          if (appointment?.Appointment === true) {
            processAppointment();
          }
          history.replace('first-appointment-overview');
          setIsFetching(false);
        } else if (
          (Boolean(patientProfile.onboarded) === false ||
          patientProfile.onboarded === PATIENT_ONBOARDING_STATUS.NONE) &&
          appointment?.Appointment === true
        ) {
          processAppointment();
        } else {
          setIsFetching(false);
        }
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [patientProfile]
  );

  return (
    <>
      {showSkipModal && (
        <SkipCardscanModal
          onSkip={handleShareInsuranceLaterClick}
          onCancel={() => setShowSkipModal(false)}
        />
      )}
      <AnimatedRoute
        nextRoute='/insurance-card-take-a-picture'
        preHeader='Insurance information'
        title="Appointment booked! Let's get your insurance information."
        showProgressBar={false}
        animationDirection={animationDirection}
        setAnimationDirection={setAnimationDirection}
      >
        <DescriptionText>
          We've sent you confirmation emails with your appointment details. To
          save you time and make it easier for you to complete your forms,
          please share your insurance information now.
        </DescriptionText>

        <LowerPageContainer>
          <ButtonBackgroundContainer
            backgroundImg={TakePhotoButton}
            onClick={handleCardScanClick}
          >
            <ButtonText>
              Scan your insurance <br />
              card with your camera.
            </ButtonText>
            <ButtonImageContainer />
          </ButtonBackgroundContainer>

          <ButtonBackgroundContainer
            backgroundImg={UploadPhotoButton}
            onClick={handleCardUploadClick}
          >
            <ButtonText>
              Upload images of your <wbr />
              insurance card.
            </ButtonText>
            <ButtonImageContainer />
          </ButtonBackgroundContainer>

          <SkipButton onClick={() => setShowSkipModal(true)}>
            I’ll share my insurance information later.
          </SkipButton>
        </LowerPageContainer>
      </AnimatedRoute>
    </>
  );
};

const ButtonImageContainer = styled.div`
  height: 100%;
  flex: 1;
`;

const ButtonBackgroundContainer = styled.button`
  align-items: center;
  border-style: none;
  background-color: ${palette.turquoise50};
  background-image: url(${({ backgroundImg }) => backgroundImg});
  background-position: right;
  background-repeat: no-repeat;
  border-radius: 8px;
  display: flex;
  height: 94px;
  margin-bottom: 16px;
  width: 100%;
`;

const ButtonText = styled.div`
  color: ${palette.navy500};
  flex: 2;
  font-family: Usual;
  font-weight: 600;
  font-size: 15px;
  line-height: 22.5px;
  padding: 16px;
  text-align: left;
  width: 100%;
`;

const DescriptionText = styled.p`
  color: ${palette.coolGray};
  font-family: 'Usual';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  max-width: 550px;
  width: 100%;
`;

const LowerPageContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 42px;
  width: 100%;
`;

const SkipButton = styled.button`
  background: transparent;
  border: none;
  color: ${palette.navy500};
  font-family: Usual;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  text-decoration: underline;
  width: 100%;
`;

export default connect(null, {
  setIsFetching,
  setShowBanner,
  trackOnLoadedScreenEvent,
  TrackInsuranceInformation,
  InsuranceCardScanButtonPressed,
  InsuranceCardUploadButtonPressed,
})(InsuranceInformationRoute);
