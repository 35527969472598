/**
 * @function handleTimeSlotKeyboardActions
 * @description - Handle keyboard actions for time slot buttons
 * @param {String} keyName - Keyboard key name (e.g. ArrowUp, ArrowDown, ArrowLeft, ArrowRight, Space, Enter) 
 * @param {HTMLButtonElement} focusedSlotBtnEl - Focused slot button element
 * @returns 
 */
export const handleTimeSlotKeyboardActions = (keyName, focusedSlotBtnEl) => {
  const focusedGridSlotEl = focusedSlotBtnEl.parentElement;
  switch (keyName) {
    case 'Space':
    case 'Enter':
      focusedSlotBtnEl.click();
      focusedSlotBtnEl.classList.remove(TIMESLOT_STATE.FOCUSED);
      focusedSlotBtnEl.blur();
      break;
    case 'ArrowLeft':
    case 'ArrowRight':
      focusedSlotBtnEl.classList.remove(TIMESLOT_STATE.FOCUSED);
      focusedSlotBtnEl.setAttribute('tabindex', -1);

      const nextSlotElName =
        keyName === 'ArrowRight' ? 'nextSibling' : 'previousSibling';
      const nextSlotFocusedEl = focusedGridSlotEl[nextSlotElName] || null;

      if (!nextSlotFocusedEl) {
        const nextNonNullableEl =
          nextSlotElName === 'nextSibling'
            ? focusedGridSlotEl.parentElement.firstChild
            : focusedGridSlotEl.parentElement.lastChild;
        nextNonNullableEl.firstChild.setAttribute('tabindex', 0);
        nextNonNullableEl.firstChild.classList.add(TIMESLOT_STATE.FOCUSED);
        nextNonNullableEl.firstChild.focus();
        break;
      }
      nextSlotFocusedEl.firstChild.setAttribute('tabindex', 0);
      nextSlotFocusedEl.firstChild.classList.add(TIMESLOT_STATE.FOCUSED);
      nextSlotFocusedEl.firstChild.focus();
      break;
    case 'ArrowUp':
    case 'ArrowDown':
      focusedSlotBtnEl.classList.remove(TIMESLOT_STATE.FOCUSED);
      focusedSlotBtnEl.setAttribute('tabindex', -1);
      const focusedCellSlotClsIndex =
        Array.from(focusedGridSlotEl.classList).find((cls) =>
          cls.includes('cell-')
        ) || null;

      if (!focusedCellSlotClsIndex) return;

      const rowSelectedEl = focusedGridSlotEl.parentElement;
      const nextRowEl =
        rowSelectedEl[
          keyName === 'ArrowUp' ? 'previousSibling' : 'nextSibling'
        ] || null;
      if (!nextRowEl) {
        const nextNonNullableRowEl =
          keyName === 'ArrowUp'
            ? rowSelectedEl.parentElement.lastChild
            : rowSelectedEl.parentElement.firstChild;
        const nextFocusedSlotCellEl =
          nextNonNullableRowEl.querySelector(`.${focusedCellSlotClsIndex}`) ||
          nextNonNullableRowEl.firstChild;
        nextFocusedSlotCellEl.firstChild.setAttribute('tabindex', 0);
        nextFocusedSlotCellEl.firstChild.classList.add(TIMESLOT_STATE.FOCUSED);
        nextFocusedSlotCellEl.firstChild.focus();
        break;
      }
      const nextFocusedSlotCellEl = nextRowEl.querySelector(
        `.${focusedCellSlotClsIndex}`
      ) || nextRowEl.firstChild;
      nextFocusedSlotCellEl.firstChild.setAttribute('tabindex', 0);
      nextFocusedSlotCellEl.firstChild.classList.add(TIMESLOT_STATE.FOCUSED);
      nextFocusedSlotCellEl.firstChild.focus();
      break;
    default:
      break;
  }
};

export const TIMESLOT_STATE = {
  AVAILABLE: 'available',
  SELECTED: 'selected',
  DISABLED: 'disabled',
  FOCUSED: 'focused',
};
