/**
 * @function handleMonthKeyboardActions
 * @description Handles the switching of the selected date button element through arrow keys and `Enter` key
 * @param {String} keyName - The key name of the key event (Enter, ArrowLeft, ArrowRight, ArrowUp, ArrowDown)
 * @param {HTMLButtonElement} currentArrowEl - The current arrow button element
 * @param {HTMLButtonElement} selectedDateEl - The selected date button element
 */
export const handleMonthKeyboardActions = (
  keyName,
  currentArrowEl,
  selectedDateEl
) => {
  switch (keyName) {
    case 'Space':
    case 'Enter':
      if (!currentArrowEl.classList.contains('disabled')) {
        currentArrowEl.click();
        currentArrowEl.classList.remove('focused');
        currentArrowEl.blur();
      }
      break;
    case 'ArrowLeft':
    case 'ArrowRight':
      currentArrowEl.classList.remove('focused');
      const nextBtnFocusedEl =
        currentArrowEl.nextSibling !== null &&
        currentArrowEl.nextSibling.classList.contains('next-btn') &&
        !currentArrowEl.nextSibling.classList.contains('disabled')
          ? currentArrowEl.nextSibling
          : currentArrowEl.previousSibling;
      nextBtnFocusedEl.classList.add('focused');
      break;
    case 'ArrowUp':
    case 'ArrowDown':
      currentArrowEl.classList.remove('focused');
      selectedDateEl.focus();
      break;
    default:
      break;
  }
};

/**
 * @function handleSwitchDayFn
 * @description Handles the switching of the selected day button element through left or right arrow keys
 * @param {'ArrowLeft'|'ArrowRight'} arrowKeyName - The key name of the arrow key ()
 * @param {HTMLButtonElement} focusedDayBtnEl - The selected day button element
 * @param {HTMLDivElement} nextDayCellEl - The next day element
 * @returns
 */
export const handleSwitchDayFn = (
  arrowKeyName,
  focusedDayBtnEl,
  nextDayCellEl
) => {
  focusedDayBtnEl.setAttribute('tabindex', -1);
  focusedDayBtnEl.classList.remove('focused');
  if (!nextDayCellEl) {
    const selectedDayCellEl = focusedDayBtnEl.parentElement;
    const upcomingDayCellEl =
      selectedDayCellEl.parentElement[
        arrowKeyName === 'ArrowLeft' ? 'lastChild' : 'firstChild'
      ];

    upcomingDayCellEl.firstChild.setAttribute('tabindex', 0);
    upcomingDayCellEl.firstChild.classList.add('focused');
    upcomingDayCellEl.firstChild.focus();
    return;
  }

  nextDayCellEl.firstChild.setAttribute('tabindex', 0);
  nextDayCellEl.firstChild.classList.add('focused');
  nextDayCellEl.firstChild.focus();
};

/**
 * @function handleDayKeyboardActions
 * @description Handles the keyboard actions of the selected day button element
 * @param {String} key - The key name of the key event (Enter, Space, ArrowLeft, ArrowRight, ArrowUp, ArrowDown) 
 * @param {HTMLButtonElement} focusedDayBtnEl - The selected day button element
 */
export const handleDayKeyboardActions = (key, focusedDayBtnEl) => {
  const focusedDayCellEl = focusedDayBtnEl.parentElement;
  switch (key) {
    case 'Space':
    case 'Enter':
      !focusedDayBtnEl.classList.contains(WEEK_DAY_STATE.DISABLED) &&
        focusedDayBtnEl.click();
      break;
    case 'ArrowLeft':
      handleSwitchDayFn(key, focusedDayBtnEl, focusedDayCellEl.previousSibling);
      break;
    case 'ArrowRight':
      handleSwitchDayFn(key, focusedDayBtnEl, focusedDayCellEl.nextSibling);
      break;
    case 'ArrowUp':
    case 'ArrowDown':
      const nextRow = focusedDayCellEl.parentElement.previousSibling;
      nextRow.querySelector('.prev-btn').classList.add(WEEK_DAY_STATE.FOCUSED);
      break;
    default:
      break;
  }
};

export const WEEK_DAY_STATE = {
  SELECTED: 'selected',
  AVAILABLE: 'available',
  FOCUSED: 'focused',
  DISABLED: 'disabled',
};
