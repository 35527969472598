import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  DontShowPasswordEye,
  ShowPasswordEye,
  CheckMark,
} from '../assets/svgs';
import { RevealPasswordIconClick } from '../actions/segment';
import { OSHI_INPUT_TYPE } from '../constants/ui';
import { palette } from '../theme/palette';

const withPasswordInput = (Component) => {
  const PasswordCmp = ({
    type,
    value,
    isValid,
    showCheckMark = false,
    RevealPasswordIconClick: trackRevealPassIconClick,
    ...restProps
  }) => {
    const [showPassword, setShowPassword] = React.useState(false);
    const handlePasswordIconClick = () => {
      setShowPassword(!showPassword);
      trackRevealPassIconClick();
    };

    return (
      <>
        <Component
          {...restProps}
          type={showPassword ? OSHI_INPUT_TYPE.TEXT : type}
        />
        <IconContainer>
          <PassRevealBtn
            type='button'
            aria-label={showPassword ? 'Hide password' : 'Show password'}
            onClick={handlePasswordIconClick}
            hasrigthcmp={showCheckMark.toString()}
          >
            {showPassword ? (
              <ShowPasswordEye color={palette.greyBorderColor} />
            ) : (
              <DontShowPasswordEye color={palette.greyBorderColor} />
            )}
          </PassRevealBtn>

          {showCheckMark && isValid && value.length > 0 ? (
            <CheckMark color={palette.mediumTurqoise} />
          ) : null}
        </IconContainer>
      </>
    );
  };
  return connect(null, { RevealPasswordIconClick })(React.memo(PasswordCmp));
};

const IconContainer = styled.div`
  position: absolute;
  right: 10px;
  bottom: 20px;
  display: flex;
  align-items: center;
`;

const PassRevealBtn = styled.button`
  margin-right: ${(props) => (props.hasrigthcmp === 'true' ? 10 : 0)}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export default withPasswordInput;
