import React, { useState, useContext, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { palette } from '../theme/palette';
import AnimatedRoute from '../components/AnimatedRoute';
import Button from '../components/Button';
import useCardScan from '../hooks/useCardScan';
import CardscanErrorModal from '../components/CardscanErrorModal';
import OshiFileUploader from '../components/OshiFileUploader';
import { CardscanContext } from '../utils/context';
import { setIsFetching } from '../actions';
import { TrackInsuranceCardUpload } from '../actions/segment';
import { ROUTES } from '../constants/routes';
import { PATIENT_INSURANCE_STATUS } from '../constants/patient';
import usePatientAPI from '../hooks/usePatientAPI';

const CardUploadRoute = ({ setIsFetching, TrackInsuranceCardUpload }) => {
  const { customPatientUpdate } = usePatientAPI();
  const [animationDirection, setAnimationDirection] = useState('mount');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [frontImage, setFrontImage] = useState();
  const [backImage, setBackImage] = useState();
  const history = useHistory();
  const [, setInsuranceInformation] = useContext(CardscanContext);

  useLayoutEffect(() => {
    TrackInsuranceCardUpload();
    // eslint-disable-next-line
  }, []);

  const handleUploadSuccess = (card) => {
    setIsFetching(false);
    setInsuranceInformation(card);
    history.replace('/policy-holder');
  };

  const handleUploadError = (error) => {
    console.error(error);
    setIsFetching(false);
    setShowErrorModal(true);
  };

  const handleSkipInsuranceInfoClick = async () => {
    setShowErrorModal(false);
    try {
      setIsFetching(true);
      await customPatientUpdate({
        insurance_card_status: PATIENT_INSURANCE_STATUS.SKIPPED,
      });
      setIsFetching(false);
      history.replace(ROUTES.FIRST_APPT_OVERVIEW, { skipInsurance: true });
    } catch (error) {
      console.log('Error updating insurance card status to skipped', error);
      setIsFetching(false);
    }
  };

  const handleCancelInsuranceInfoClick = async () => {
    history.replace(ROUTES.INSURANCE_INFO);
  };

  const { uploadCards } = useCardScan(handleUploadSuccess, handleUploadError);

  const upload = async () => {
    setIsFetching(true);
    await uploadCards({ front: frontImage, back: backImage });
  };

  return (
    <>
      {showErrorModal && (
        <CardscanErrorModal
          onSkip={handleSkipInsuranceInfoClick}
          onRetry={handleCancelInsuranceInfoClick}
        />
      )}
      <AnimatedRoute
        preHeader='Insurance information'
        title='Upload images of your insurance card.'
        showProgressBar={false}
        animationDirection={animationDirection}
        setAnimationDirection={setAnimationDirection}
      >
        <DescriptionText>
          Upload images of your insurance card. information below. File types
          accepted are JPEG, PNG, or PDF up to 10MB.
        </DescriptionText>
        <UploadButtonsRow>
          <UploadButtonContainer>
            <OshiFileUploader
              id='frontCardFile'
              label='Upload front of insurance card'
              onFileChange={(files) => setFrontImage(files[0])}
              fileType='image/jpeg, image/png, application/pdf'
            />
          </UploadButtonContainer>
          <UploadButtonContainer>
            <OshiFileUploader
              id='backCardFile'
              label='Upload back of insurance card'
              onFileChange={(files) => setBackImage(files[0])}
              fileType='image/jpeg, image/png, application/pdf'
            />
          </UploadButtonContainer>
        </UploadButtonsRow>
        <BottomContainer>
          <Button
            styles={{ width: '100%' }}
            disabled={!(!!frontImage && !!backImage)}
            onClick={upload}
          >
            Submit
          </Button>
        </BottomContainer>
      </AnimatedRoute>
    </>
  );
};

const UploadButtonContainer = styled.div`
  display: flex;
  flex: none;
  flex-direction: column;
  width: calc(50% - 12px);
  @media only screen and (max-width: 769px) {
    width: 100%;
  }
`;

const UploadButtonsRow = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  padding: 64px 0;
  gap: 24px;
  @media only screen and (max-width: 769px) {
    flex-direction: column;
  }
`;

const DescriptionText = styled.p`
  color: ${palette.coolGray};
  font-family: 'Usual';
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  max-width: 550px;
  width: 100%;
`;

const BottomContainer = styled.div`
  display: flex;
  flex: 1;
`;

export default connect(null, { setIsFetching, TrackInsuranceCardUpload })(
  CardUploadRoute
);
