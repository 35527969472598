import React from 'react';
import styled from 'styled-components';

import Typography from '../atoms/Typography';
import Button from '../components/Button';
import { palette } from '../theme/palette';
import withTracker from '../wrappers/withTracker';
import { NetworkErrorIcon } from '../assets/svgs';
import { useHistory } from 'react-router-dom';
import AnimatedRoute from '../components/AnimatedRoute';

function NetworkError() {
  const animationDirection = 'mount';
  const history = useHistory();
  function handleTryAgain() {
    if (
      history.location &&
      history.location.state &&
      history.location.state.redirectLink
    ) {
      history.replace(history.location.state.redirectLink.split('/')[3]);
    }
  }

  return (
    <AnimatedRoute
      title=''
      animationDirection={animationDirection}
      showProgressBar={false}
    >
      <Container>
        <ImageContainer>
          <NetworkErrorIcon />
        </ImageContainer>

        <TopTextContainer>
          <Typography
            type='h1'
            styles={{
              textAlign: 'center',
              color: palette.darkPrimaryText,
              lineHeight: '26.94px',
            }}
          >
            It seems like something went wrong...
          </Typography>
        </TopTextContainer>

        <BottomTextContainer>
          <Typography styles={{ color: palette.darkPrimaryText }}>
            Please check your connection and try again.
          </Typography>
        </BottomTextContainer>
        <TryAgainButtonContainer>
          <Button disabled={false} onClick={handleTryAgain}>
            Try Again
          </Button>
        </TryAgainButtonContainer>

        <ContactSupportContainer>
          <StyledLink href='mailto:carecoordinator@oshihealth.com?subject=Customer Support'>
            Contact Support
          </StyledLink>
        </ContactSupportContainer>
      </Container>
    </AnimatedRoute>
  );
}

const Container = styled.div`
  display: flex;
  padding: 10px 30px 35px;
  align-items: center;
  @media (max-width: 420px) {
    padding: 0 16px;
  }

  flex-direction: column;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 88px;
`;

const TryAgainButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`;

const ContactSupportContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledLink = styled.a`
  position: relative;
  // top: 5px;
  font-family: Usual;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 131.7%;
  letter-spacing: 0.06em;
  text-decoration-line: underline;
  color: #818d9b;
  cursor: pointer;
  @media (max-width: 768px) {
    position: relative;
    bottom: 10px;
  }
`;

const TopTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 280px;
  margin-top: 32.7px;
  margin-bottom: 24px;
`;
const BottomTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 57px;
`;

export default withTracker(NetworkError, 'Network Error');
