import {
  regularSignupRouteList,
  genericSignupRouteList,
} from '../constants/routes';
import { uiTypes } from '../constants';
import { localStorageGet } from '../utils/localStorageHelper';

const initialState = {
  isFetching: false,
  isNameRoute: false,
  showBanner: false,
  currentProgress: 0,
  totalProgressSteps: regularSignupRouteList.length,
  isGenericSignupFlow: !localStorageGet('partner')?.partner || localStorageGet('isReferringProvider')?.isReferringProvider,
  hideRightSidedBanner: false
};

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case uiTypes.IS_FETCHING:
      return { ...state, isFetching: action.isFetching };
    case uiTypes.IS_NAME_ROUTE:
      return { ...state, isNameRoute: action.isNameRoute };
    case uiTypes.SHOW_BANNER:
      return { ...state, showBanner: action.showBanner };
    case uiTypes.TOTAL_PROGRESS_STEPS:
      const totalStepsLength = state.isGenericSignupFlow
        ? genericSignupRouteList.length
        : regularSignupRouteList.length
      return { ...state, totalProgressSteps: totalStepsLength }
    case uiTypes.HIDE_RIGHT_SIDED_BANNER:
      return { ...state, hideRightSidedBanner: action.hideRightSidedBanner };
    case uiTypes.CHANGE_PROGRESS:
      return {
        ...state,
        currentProgress: action.payload,
      };
    case uiTypes.CHANGE_SIGN_UP_FLOW:
      return {
        ...state,
        isGenericSignupFlow: action.payload,
      };
    default:
      return state;
  }
};

export default uiReducer;
