import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useZipEligibilityUILogic from './useZipEligibilityUILogic';
import {
  stateAbbreviations,
  STATE_ERROR_MAP,
} from '../constants/stateAbbreviations';
import { localStorageSave, localStorageGet } from '../utils/localStorageHelper';
import { getBirthdate } from '../utils/getBirthdate';
import useAddressUIForm from './useAddressUIForm';
import usePatientLeadAPI from './usePatientLeadAPI';
import { ROUTES } from '../constants/routes';
import { PATIENT_LEAD_STATUSES } from '../constants/salesforceTypes';

/**
 * Custom hook to handle UI logic processing for /address UI page.
 *
 * @param { Object } uiLogicConfig - Set of properties/methods to handle for instance tracking segment analytics
 * and custom UI state changes.
 * @returns { Object } uiLogicParams - Set of properties/methods to handle all UI logic behind /address page
 * For this case, UI form logic and UI navigation logic configuration are sent back to UI component to be used in its
 * UI template.
 */

const useAddressPageUILogic = ({
  setIsFetching,
  userConfirmAddressSubmit,
  OffBoardingAvailableStateDebug,
}) => {
  const [animationDirection, setAnimationDirection] = useState('mount'); // mount, back, forward
  const [disabled, setDisabled] = useState(true);
  const [isAPILoading, setIsAPILoading] = useState(false);
  const [isBtnPressed, setIsBtnPressed] = useState(false);
  const [errors, setErrors] = useState(false);
  const [zipError, setZipError] = useState(false);
  const [stateError, setStateError] = useState(null);
  const history = useHistory();
  const { availableStates, zipLookUp } = useZipEligibilityUILogic(
    setIsAPILoading
  );
  const { savePatientLead } = usePatientLeadAPI();
  const gender = localStorageGet('gender');
  const birthdate = getBirthdate();
  const isGenericSignupFlow = useSelector(
    (state) => state.uiReducer?.isGenericSignupFlow
  );

  const stateSearchFn = (searchableList, valueToSearch) => {
    return valueToSearch.length > 0
      ? searchableList.filter((it) => {
          const newValue = valueToSearch.toLowerCase();
          return (
            it.label.toLowerCase().search(newValue) >= 0 ||
            it.value.toLowerCase().search(newValue) >= 0
          );
        })
      : searchableList;
  };

  const handleStateSelectionClick = (ev) => {
    const { textContent } = ev.target;
    formik.handleChange('state')(textContent);
  };

  const handleSubmitFn = async (values) => {
    setIsFetching(true);
    try {
      if (
        values.state &&
        !Object.values(stateAbbreviations).includes(values.state)
      ) {
        setStateError(STATE_ERROR_MAP.INVALID_STATE);
        setIsFetching(false);
        return;
      }

      const locationObject = await zipLookUp(values.zip);
      if (locationObject === 'invalid') {
        setZipError(true);
        setIsFetching(false);
        return;
      }

      const stateValue = stateAbbreviations[locationObject.state] || null;
      localStorageSave('state', stateValue);

      if (values.state && stateValue && stateValue !== values.state) {
        setStateError(STATE_ERROR_MAP.UNMATCHED_STATE);
        setIsFetching(false);
        return;
      }

      const currentAddress = {
        streetAddress: values.streetAddress,
        apartment: values.apartment,
        city: values.city,
        state: values.state,
        zip: values.zip,
        gender,
        birthdate,
      };

      localStorageSave('address', { address: currentAddress });

      OffBoardingAvailableStateDebug(locationObject.state, {
        availableStates,
        stateValue,
      });

      if (!isGenericSignupFlow && !availableStates.includes(stateValue)) {
        setIsFetching(false);
        history.push({
          pathname: ROUTES.OFF_BOARDING,
          state: {
            address: currentAddress,
          },
        });
        return;
      }

      if (!isGenericSignupFlow) {
        const leadUpdate = {
          status: PATIENT_LEAD_STATUSES.QUALIFIED,
          zip_code: values.zip,
          state: values.state,
          street: values.streetAddress,
          city: values.city,
          gender,
          birthdate,
        };

        if (values.apartment) {
          leadUpdate.address2 = values.apartment;
        }

        await savePatientLead('update', leadUpdate);
      }

      setIsFetching(false);
      userConfirmAddressSubmit();
      setAnimationDirection('unmount');
    } catch (error) {
      setIsFetching(false);
    }
  };

  const handleOnKeyPressFn = (evt, formik) => {
    if (evt.keyCode === 13) {
      evt.preventDefault();
      if (!disabled && formik.isValid) {
        handleBtnClick();
      }
    }
  };
  const { formik, handleSubmit, handleOnKeyPress } = useAddressUIForm({
    handleSubmitFn,
    handleOnKeyPressFn,
  });

  const { errors: formErrors } = formik;
  const {
    zip: zipValue,
    state: stateValue,
    streetAddress: addressValue,
  } = formik.values;
  const zipLength = zipValue?.length;
  const addressLength = addressValue?.length;

  // this useEffect keeps track of error state used to manage error messages
  useEffect(() => {
    if (!errors && Object.keys(formErrors).length !== 0) {
      setErrors(true);
    }
    if (errors && !zipError && Object.keys(formErrors).length === 0) {
      setErrors(false);
    }
    if (zipError || stateError) {
      setErrors(true);
    }
    if (
      zipError &&
      typeof zipLength === 'number' &&
      zipLength > 0 &&
      zipLength < 5
    ) {
      setZipError(false);
      setErrors(false);
    }
    // Check if state is valid through zip code and its name
    if (stateError && Object.values(stateAbbreviations).includes(stateValue)) {
      if (localStorageGet('state') === stateValue) {
        setStateError(null);
        setErrors(false);
      }
    }
  }, [errors, formErrors, zipError, stateError, stateValue, zipLength]);

  useEffect(() => {
    if (
      typeof addressLength === 'number' &&
      addressLength > 1 &&
      zipLength === 5 &&
      formik.isValid &&
      disabled
    ) {
      setDisabled(false);
    }
    if (!disabled && !formik.isValid) {
      setDisabled(true);
    }
  }, [addressLength, formik.isValid, disabled, zipLength]);

  const handleBtnClick = (ev) => {
    setIsBtnPressed(true);
  };

  useEffect(() => {
    if (isAPILoading && isBtnPressed) {
      handleBtnClick();
    } else if (
      !isAPILoading &&
      isBtnPressed &&
      ((!isGenericSignupFlow && availableStates?.length > 0) ||
        isGenericSignupFlow)
    ) {
      setIsBtnPressed(false);
      handleSubmitFn(formik.values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAPILoading, isBtnPressed, formik.values, availableStates]);

  return {
    formik,
    zipError,
    stateError,
    disabled,
    isBtnPressed,
    isAPILoading,
    handleBtnClick,
    handleStateSelectionClick,
    errors,
    animationDirection,
    isGenericSignupFlow,
    setAnimationDirection,
    handleSubmit,
    stateSearchFn,
    handleOnKeyPress,
  };
};

export default useAddressPageUILogic;
