import React from 'react';
import styled from 'styled-components';

const MAX_ITEMS = 3;

/**
 * @function OshiACResultList
 * @module components/OshiACResultList
 * @description Renders the autocomplete result list when list data is not empty
 * The UI list gets saved in a state in order to manipulate basic UI DOM style changes like
 * highlighting the selected item either by mouse or keyboard (arrow keys), set fixed height,
 * and scrolling the list from its parent component custom hook.
 * @param {{list: Array, onItemClick: Function, setRenderedList: Function}} props
 * @param {React.Ref} ref
 * @returns {JSX.Element}
 */
const OshiACResultList = React.forwardRef(
  ({ id, parentId, list, onItemClick, ...props }, ref) => {
    const [itemHeight, setItemHeight] = React.useState(0);

    React.useEffect(() => {
      if (list.length === 0) return;

      setItemHeight(
        Array.from(ref.current.children)
          .slice(0, MAX_ITEMS)
          .reduce((acc, it) => acc + it.clientHeight, 0)
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list]);

    return (
      <PopupList
        {...props}
        id={id}
        ref={ref}
        hasoverflow={(list.length > MAX_ITEMS).toString()}
        style={{
          maxHeight: list.length > MAX_ITEMS ? itemHeight : 'auto',
        }}
      >
        {list.map((it, idx) => (
          <PopupListItem
            key={it.value}
            id={`${parentId}${idx}`}
            value={it.value}
            role='option'
            onClick={onItemClick}
            aria-selected={
              ref.current &&
              ref.current.querySelector(`.selected`) !== null
            }
            aria-label={it.label}
          >
            {it.label}
          </PopupListItem>
        ))}
      </PopupList>
    );
  }
);

const PopupList = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  left: 0;
  top: 0;
  background: rgb(255, 255, 255);
  position: absolute;
  z-index: 10;
  border-radius: 4px;
  overflow-y: ${(props) =>
    props.hasoverflow === 'true' ? 'scroll' : 'hidden'};
`;

const PopupListItem = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  background: transparent;
  border-radius: 4px;
  padding: 10px 10px 10px 3%;
  color: rgb(59, 91, 128);
  cursor: default;
  font-size: 14px;
  font-family: Usual;
  line-height: 24px;
  &.focused,
  &:hover {
    background: rgb(59, 91, 128);
    color: rgb(255, 255, 255);
  }
`;

export default OshiACResultList;
