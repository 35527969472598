import React from 'react';
const OshiContentLink = ({ href, imgSrc, imgAlt, style = {} }) => {
  return (
    <a href={href} target='_blank' rel='noopener noreferrer'>
      <img src={imgSrc} alt={imgAlt} style={style} />
    </a>
  );
};

export default React.memo(OshiContentLink);
