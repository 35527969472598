function getAge(dateString) {
  const today = new Date();
  // dateString comes as xx / xx / xxxx
  // and new Date() on iOS thinks that is an invalid
  // date and messes this alg up. we need to split
  // and join to make it xx/xx/xxxx
  const birthDate = new Date(dateString.split(' / ').join('/'));

  let age = today.getFullYear() - Number(dateString.split(' / ')[2]);
  
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export default getAge;
