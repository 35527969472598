import { useEffect } from 'react';
import { API, Auth } from 'aws-amplify';

const useCardScan = (onSuccess = () => {}, onFailure = () => {}) => {
  let cardId, statusSubscription, sessionToken;
  const cardScanUrl =
    process.env.REACT_APP_ENV === 'prod'
      ? 'https://api.cardscan.ai'
      : 'https://sandbox.cardscan.ai';
  let uploadStatus = 'none';

  useEffect(() => {
    (async () => await authCardScan())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const parseCardData = (card) => {
    let output = {};
    let { details } = card;

    for (let field in details) {
      if (details[field] === undefined) continue;

      if (Array.isArray(details[field])) {
        if (field === 'payer_name') {
          output[field] = details[field][0].value;
          continue;
        }
        const arr = [];

        for (let obj of details[field]) {
          arr.push(obj.value);
        }
        output[field] = arr;
      } else if (field === 'start_date') {
        const start_date = new Date(details[field].value);
        output[field] = start_date.getTime();
      } else {
        output[field] = details[field].value;
      }
    }

    const { front, back } = card['images'];

    output['card_front'] = front?.url;
    output['card_back'] = back?.url;

    onSuccess(output);

    return output;
  };

  const responseSuccess = (status) => {
    switch (status) {
      case 200:
      case 201:
      case 204:
        return true;
      default:
        return false;
    }
  };

  const authCardScan = async () => {
    if (sessionToken !== undefined) return sessionToken;

    const cognitoUser = await Auth.currentAuthenticatedUser();
    const userId = cognitoUser.attributes.sub;

    await API.get('oshiAPI', `/documents/session?cognito_id=${userId}`)
      .then((session) => {
        sessionToken = session?.Token;
      })
      .catch((error) => {
        console.error(error);
        sessionToken = '';
        onFailure();
      });

    return sessionToken;
  };

  const createNewCard = async () => {
    const response = await fetch(`${cardScanUrl}/v1/cards`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionToken}`,
      },
      body: JSON.stringify({ enable_backside_scan: true }),
    });

    const responseJSON = await response.json();
    cardId = await responseJSON?.card_id;
  };

  const generateUploadURL = async (orientation) => {
    if (cardId === undefined) await createNewCard();

    const response = await fetch(
      `${cardScanUrl}/v1/cards/${cardId}/generate-upload-url`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionToken}`,
        },
        card_id: cardId,
        body: JSON.stringify({ orientation }),
      }
    );

    const responseJSON = await response.json();
    if (!responseSuccess(response?.status)) onFailure();
    return await responseJSON;
  };

  const upload = async (orientation, file) => {
    if (!cardId) await createNewCard();

    const { upload_url, upload_parameters } = await generateUploadURL(
      orientation
    );

    let formData = new FormData();
    for (let key in { ...upload_parameters, 'Content-Type': file.type }) {
      formData.append(key, upload_parameters[key]);
    }

    formData.append('file', file);

    const response = await fetch(upload_url, {
      method: 'POST',
      body: formData,
    });

    return await response;
  };

  const getCard = async (token, id) => {
    const response = await fetch(`${cardScanUrl}/v1/cards/${id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return await response.json();
  };

  const uploadCards = async ({ front, back }) => {
    await authCardScan();
    await upload('front', front);

    statusSubscription = setInterval(async () => {
      const card = await getCard(sessionToken, cardId);

      switch (card?.state) {
        case 'error':
          clearInterval(statusSubscription);
          onFailure();
          break;
        case 'completed':
          clearInterval(statusSubscription);
          parseCardData(card);
          break;
        case 'backside_processing':
          if (uploadStatus !== 'backside_processing') {
            uploadStatus = 'backside_processing';
            await upload('back', back);
          }
          break;
        default:
      }
    }, 1000);
  };

  return { uploadCards, authCardScan, parseCardData, getCard };
};

export default useCardScan;
