import React from 'react';


/**
 * @function useLink
 * @description A hook to handle link element keyup events.
 * @param {{
 *  ref: React.Ref,
 *  onClick: () => void
 * }} params - Object param containing ref and onClick function bound to the link element.
 * @returns {React.RefObject} linkRef - A reference to the link element.
 */
const useLink = ({ref, onClick}) => {
  const linkRef = React.useRef(ref);

  React.useEffect(() => {
    const handleKeyUp = (event) => {
      const focusedElement = document.activeElement;
      if (focusedElement === linkRef.current) {
        if (event.key === 'Enter') {
          onClick();
        }
      }
    };
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keyup', handleKeyUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { linkRef };
};

export default useLink;