export const OSHI_INFO_BANNER_TYPE = {
  REGULAR: 'regular',
  INFO: 'info',
};

export const OSHI_INFO_BANNER_SENTIMENT = {
  SUCCESS: 'success',
  ERROR: 'error',
};

export const OSHI_INPUT_TYPE = {
  TEXT: 'text',
  PASSWORD: 'password',
  EMAIL: 'email',
  TEL: 'tel',
  CHECKBOX: 'checkbox',
  RADIO: 'radio',
  SELECT: 'select',
  AUTOCOMPLETE: 'autocomplete',
};

export const OSHI_FORM_INPUT_STATE = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  ERROR: 'ERROR',
  DISABLED: 'DISABLED',
};
