import React from 'react';
import styled from 'styled-components';

import { palette } from '../theme/palette';

/**
 * @function OshiFileUploader
 * @description Custom and reusable UI component to upload files.
 * @param {{
 *      id: string,
 *      label: string,
 *      fileType: string,
 *      onFileChange: (files: File[]) => void,
 *      disabled: boolean,
 *      multiple: boolean
 * }} props - Component props
 * @returns {React.ReactElement}
 */
const OshiFileUploader = ({
  id,
  label,
  fileType,
  onFileChange,
  disabled = false,
  multiple = false,
}) => {
  const fileRef = React.useRef(null);
  const [uploadedFiles, setUploadedFiles] = React.useState([]);

  const getFileExtension = React.useCallback(
    (name) => (!!name ? name.slice(name.lastIndexOf('.')) : null),
    []
  );

  const getFileName = React.useCallback(
    (name) => (!!name ? name.slice(0, name.lastIndexOf('.')) : null),
    []
  );

  // Handle file change events
  const handleFileChange = React.useCallback(
    (event) => {
      const files = Array.from(event.target.files);
      setUploadedFiles(files);

      if (files.length > 0) {
        onFileChange(files);
      }
    },
    [onFileChange]
  );

  // Handle click events
  const handleClick = React.useCallback(
    (event) => {
      if (disabled || !fileRef.current) return;

      event.preventDefault();
      fileRef.current.click();
    },
    [disabled]
  );

  // Handle key up events
  const handleKeyUp = React.useCallback(
    (event) => {
      if (event.code === 'Enter') {
        handleClick(event);
      }
    },
    [handleClick]
  );

  const renderUploadedFilesList = React.useCallback(() => {
    if (!fileRef.current) return null;

    const filesList = Array.from(fileRef.current.files);
    if (filesList.length === 0) return null;

    return (
      <UploadedFileListContainer>
        {filesList.map((file, idx) => (
          <UploadedFileListItem key={`${file?.name}_${idx}`}>
            <UploadedFileLabel aria-label={`${file?.name}`}>
              <UploadedFileName aria-hidden='true'>
                {file ? getFileName(file?.name) : ''}
              </UploadedFileName>
              <UploadedFileExtension aria-hidden='true'>
                {file ? getFileExtension(file?.name) : ''}
              </UploadedFileExtension>
            </UploadedFileLabel>
          </UploadedFileListItem>
        ))}
      </UploadedFileListContainer>
    );
  }, [getFileName, getFileExtension]);

  return (
    <>
      <FileBtn
        role='button'
        tabIndex={0}
        aria-disabled={disabled}
        onClick={handleClick}
        htmlFor={id}
        onKeyUp={handleKeyUp}
        aria-describedby={`${id}UploadedFiles`}
      >
        {label}
      </FileBtn>
      <HiddenText id={`${id}UploadedFiles`}>
        {`${
          uploadedFiles.length === 0
            ? 'No files uploaded'
            : `Uploaded files: ${uploadedFiles.length}`
        }`}
      </HiddenText>
      <FileInput
        id={id}
        ref={fileRef}
        type='file'
        accept={fileType}
        onChange={handleFileChange}
        multiple={multiple}
        hidden
        aria-hidden='true'
      />
      {renderUploadedFilesList()}
    </>
  );
};

const FileBtn = styled.label`
  background-color: ${palette.white};
  border: 2px solid ${palette.turquoise200};
  border-radius: 100px;
  color: ${palette.turquoise800};
  cursor: pointer;
  font-family: Usual;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  padding: 12px 25px;
  text-align: center;
`;

const FileInput = styled.input``;

const UploadedFileListContainer = styled.ul`
  display: flex;
  flex-direction: column;
`;

const UploadedFileListItem = styled.li`
  list-style-type: none;
  display: flex;
`;

const UploadedFileLabel = styled.p`
  color: ${palette.coolGray500};
  height: 21px;
  font-family: Usual;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

const UploadedFileName = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const HiddenText = styled.span`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;

const UploadedFileExtension = styled.span``;

export default React.memo(OshiFileUploader);
