import React from 'react';

import { OSHI_INPUT_TYPE } from '../constants/ui';
import CheckableBox from './CheckableBox';

/**
 * @function OshiRadioButton
 * @description Built on top of CheckableBox, this reusable UI radio button component behaves with fundamental functionality
 * of radio-button-html-based element. 
 * Its radio-button icon is customized with a check-mark svg-based UI component and it's composed also by Web ARIA attributes
 * @param {string} id - Name text to handle linking UI form functionality with this component.
 * @param {string} value - Selected radio button value represented as text
 * @param {boolean} checked - Boolean value to verify is radio-button was checked or not.
 * @param {React.ReactNode} children - Custom UI components to be shown as radio-button label component.
 * @param {number} tabIndex - Number value to handle focusability of this component.
 * @param {boolean} error - Boolean value to verify if this component has an error from form validation
 * @param {(value: any): void} onClick - UI event that gets called when this component is clicked and passes as argument its value.
 * @returns {React.ReactElement}
 */
const OshiRadioButton = ({
  id,
  onClick,
  value,
  checked,
  children,
  error = false,
  tabIndex = 0,
}) => {
  return (
    <CheckableBox
      id={id}
      type={OSHI_INPUT_TYPE.RADIO}
      tabIndex={tabIndex}
      onClick={onClick}
      label={children}
      value={value}
      checked={checked}
      hasError={error}
      hasContainerBorder
    />
  );
};

export default React.memo(OshiRadioButton);
