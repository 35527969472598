import React from 'react';
import styled from 'styled-components';

import Button from './Button';
import useLink from '../hooks/useLink';

const OshiLink = React.forwardRef(
  ({ onClick, buttonTitle, styles = {}, containerStyles = {} }, ref) => {
    const { linkRef } = useLink({ ref, onClick });

    return (
      <Container style={containerStyles}>
        <Button
          role='link'
          btnAs='link'
          onClick={onClick}
          styles={{
            ...styles,
            backgroundColor: 'transparent',
          }}
          ref={linkRef}
        >
          {buttonTitle}
        </Button>
      </Container>
    );
  }
);

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  ${Button} button {
    width: auto;
  }
`;

export default OshiLink;
