import { API } from 'aws-amplify';

/**
 * Custom hook that collects partner-based API endpoints to use across the app
 * @returns { { getPartnersList(): Promise<Partner[]> | [], getPayersList(): Promise<Partner[]> | [] } }
 */
const usePartnersAPI = () => {
  /**
   * @function getPartnersList
   * @description - API function that returns a list of current & available Oshi partners
   * @returns { Promise<Partner[]> | [] }
   */
  const getPartnersList = async () => {
    try {
      return await API.get('oshiAPI', `/service/partners`);
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  /**
   * @function getPayersList
   * @description - API function that returns a list of available billable payers for a given referring provider
   * @returns { Promise<Partner[]> | []}
   */
  const getPayersList = async (partner) => {
    try {
      return await API.get(
        'oshiAPI',
        partner ? `/service/payers?payer=${partner}` : '/service/payers'
      );
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  return {
    getPartnersList,
    getPayersList,
  };
};

export default usePartnersAPI;
