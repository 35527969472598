/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
import React from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import { palette } from '../theme/palette';
import { getHeadlineListUIComp, getTitleText } from '../utils/stringHelpers';
import { ZeroCostMelonUnderline } from '../assets/svgs';
import { isAComponent } from '../utils/domHelpers';

const NewCustomTitle = ({
  title = 'Join Oshi Health.',
  subHeader = '',
  hasZeroCostPartner = false,
  setShowBanner = null,
  style,
}) => {
  React.useLayoutEffect(() => {
    setShowBanner &&
      setShowBanner({
        sentiment: 'information',
        cmp: (
          <BannerTextContainer>
            {hasZeroCostPartner ? (
              <Text role='region' aria-hidden="true">
                Get seen right away by a GI provider — at&nbsp;
                <ZeroCostContainer>
                  <ZeroCost>$0 cost</ZeroCost>
                  <ZeroCostUnderline>
                    <ZeroCostMelonUnderline />
                  </ZeroCostUnderline>
                </ZeroCostContainer>
                <Text>&nbsp;to you.</Text>
              </Text>
            ) : (
              <Text>Get seen right away by a GI provider.</Text>
            )}
          </BannerTextContainer>
        ),
        text: `Get seen right away by a GI provider${
          hasZeroCostPartner ? ' at $0 cost to you.' : ''
        } `,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setShowBanner, hasZeroCostPartner]);

  const getTitle = React.useCallback(() => {
    if (isAComponent(title)) {
      return title;
    }
    if (!isMobile) {
      return getHeadlineListUIComp(getTitleText(title), MainHeadline);
    }
    return <MainHeadline>{title.replace('<wbr />', '')}</MainHeadline>;
  }, [title]);

  const getSubHeader = React.useCallback(() => {
    if (isAComponent(subHeader)) {
      return subHeader;
    }
    return <SubHeadline>{subHeader}</SubHeadline>;
  }, [
    subHeader
  ]);

  return (
    <Container style={style}>
      <InnerContainer>
        <HeadlineContainer>
          {getTitle()}
          {getSubHeader()}
        </HeadlineContainer>
      </InnerContainer>
    </Container>
  );
};

const BannerTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: ${palette.melon300};
  width: 100%;
`;
const Text = styled.div`
  display: inline-block;
  color: ${palette.navy500};
  font-family: 'Usual';
  font-size: 14px;
  line-height: 10px;
  vertical-align: middle;
`;
const ZeroCostContainer = styled.div`
  display: inline-block;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding-top: 4px;
  vertical-align: middle;
`;
const ZeroCostUnderline = styled.div`
  line-height: 0;
`;

const ZeroCost = styled.div`
  color: ${palette.navy500};
  font-family: 'Usual';
  font-size: 14px;
  font-weight: 700;
  line-height: 150%;
`;
const Container = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  @media only screen and (min-width: 769px) {
    align-items: flex-start;
  }
`;
const InnerContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  z-index: 10;
`;

const HeadlineContainer = styled.section`
  display: flex;
  flex-direction: column;
  padding: 24px 0;
  z-index: 10;
`;

const MainHeadline = styled.h1`
  font-family: Source Serif Pro;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 31.2px;
  color: ${palette.white};
  /* h1.desktop */
  @media only screen and (min-width: 769px) {
    font-weight: 700;
    font-size: 56px;
    line-height: 130%; /* 72.8px */
    color: ${palette.navy500};
  }
`;

const SubHeadline = styled.p`
  margin-top: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  font-family: 'Usual';
  color: ${palette.white};
  @media only screen and (min-width: 769px) {
    font-size: 16px;
    color: ${palette.navy500};
  }
`;
export default NewCustomTitle;
