import React from 'react';

import { OSHI_INPUT_TYPE } from '../constants/ui';
import CheckableBox from './CheckableBox';

/**
 * @function OshiCheckBox
 * @description Built on top of CheckableBox, this reusable UI checkbox component behaves with fundamental functionality
 * of checkbox-html-based element.
 * Its checkbox icon is customized with a check-mark svg-based UI component and it's composed also by Web ARIA attributes
 * @param {string} id - Name text to handle linking UI form functionality with this component.
 * @param {string} value - Selected checkbox button value represented as text
 * @param {boolean} checked - Boolean value to verify is checkbox was checked or not.
 * @param {React.ReactNode} children - Custom UI components to be shown as checkbox label component.
 * @param {number} tabIndex - Number value to handle focusability of this component.
 * @param {boolean} error - Boolean value to verify if this component has an error from form validation
 * @param {(value: any): void} onClick - UI event that gets called when this component is clicked and passes as argument its value.
 * @returns {React.ReactElement}
 */
const OshiCheckBox = ({
  id,
  onClick,
  value,
  checked,
  children,
  error = false,
  tabIndex = 0,
  style = {},
  labelStyle = {},
}) => {
  const checkboxRef = React.useRef(null);

  React.useEffect(() => {
    if (!id) return;

    const el = checkboxRef.current;
    const handleKeyPress = (e) => {
      if (e.code === 'Space') {
        const elFocused = el && el === document.activeElement;
        if (elFocused) {
          e.preventDefault();
          el.click();
        }
      }
    };
    window.addEventListener('keypress', handleKeyPress);
    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, [id]);

  return (
    <CheckableBox
      id={id}
      ref={checkboxRef}
      type={OSHI_INPUT_TYPE.CHECKBOX}
      tabIndex={tabIndex}
      onClick={onClick}
      label={children}
      value={value}
      shape='squared'
      checked={checked}
      hasError={error}
      containerStyles={style}
      labelContainerStyles={labelStyle}
    />
  );
};

export default React.memo(OshiCheckBox);
