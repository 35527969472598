import React from 'react';
import styled from 'styled-components';
import Lottie from 'lottie-react-web';

import BtnAnimation from '../assets/btn-loader.json'
import { palette } from '../theme/palette';


const Button = React.forwardRef(
  (
    {
      type,
      bgcolor,
      children,
      color,
      onClick,
      disabled = false,
      role = 'button',
      isLoading,
      styles,
      btnAs = 'button',
      ...restProps
    },
    ref
  ) => {
    return (
      <Btn
        disabled={disabled}
        ref={ref}
        className={btnAs}
        type={type || 'button'}
        onClick={isLoading ? null : onClick}
        role={role}
        aria-disabled={disabled}
        style={{
          backgroundColor: bgcolor || palette.melon,
          color: color || palette.melon800,
          opacity: disabled && !isLoading ? 0.4 : 1,
          cursor: isLoading ? 'wait' : 'pointer',
          ...styles,
        }}
        {...restProps}
      >
        {isLoading ? 
        <SvgContainer>
          <Lottie
            options={{
              animationData: BtnAnimation,
            }}
            ariaLabel='Loading'
            ariaRole='img'
          />
          </SvgContainer>
         : <span>{children}</span>}
      </Btn>
    );
  }
);

const Btn = styled.button`
  height: 41px;
  border-radius: 100px;
  min-width: 192px;
  border: none;
  position: relative;

  /* ui/primary button */

  font-family: 'Usual';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height, or 121% */
  letter-spacing: 0.04em;
  text-transform: capitalize;

  font-feature- @media only screen and (min-width: 429px) {
    padding: 0 32px;
  }

  &.link {
    background: transparent;
    color: ${palette.coolGray};
    font-weight: 600;
    font-size: 14px;
    text-decoration-line: underline;
  }

  &.link:focus,
  &.link:active {
    background: #ffffff;
  }

  width: 100%;
  @media only screen and (min-width: 429px) {
    width: 183px;
  }
`;

const SvgContainer =  styled.div`
    position: absolute;
    height: 200%;
    width: 100%;
    top: -50%;
`

export default Button;
