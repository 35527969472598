import React from 'react';
import { findFirstFocusableElement } from '../utils/domHelpers';

/**
 * @function useModal
 * @description Custom hook to handle the modal logic
 * @param {{onClose: () => void }} param - Map of fields with custom values link to UI modal component
 * @returns {
 *  {
 *      modalRef: React.RefObject<HTMLElement>,
 *      handleOnClose: (ev: Event) => void
 *  }
 * } // Map of required fields to be used in the UI modal component
 */
const useModal = ({ onClose }) => {
  const modalRef = React.useRef(null);

  /**
   * @description Handle the close event for the modal
   * @param {Event} ev
   * @returns {void}
   */
  const handleOnClose = React.useCallback(
    (ev) => {
      onClose && onClose();
      ev.stopPropagation();
    },
    [onClose]
  );

  // Handle focus events for this widget
  React.useEffect(() => {
    const handleFocus = (event) => {
      if (!modalRef.current || event.target === window) {
        return;
      }
      if (modalRef.current && modalRef.current.contains(event.target)) {
        findFirstFocusableElement(event.target);
      } else {
        findFirstFocusableElement(modalRef.current);
      }
    };
    window.addEventListener('focus', handleFocus, true);
    return () => {
      window.removeEventListener('focus', handleFocus, true);
    };
  }, []);

  // Handle keyup events for this widget
  React.useEffect(() => {
    const handleKeyUp = (event) => {
      if (
        modalRef.current &&
        modalRef.current.contains(event.target) &&
        event.key === 'Escape'
      ) {
        handleOnClose(event);
      }
    };
    document.addEventListener('keyup', handleKeyUp);
    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle click event of this widget
  React.useEffect(() => {
    const handleClickOutside = (event) => {
      // Close the modal if the click is outside the modal
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleOnClose(event);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Focus the first focusable element when the modal is rendered
  React.useEffect(() => {
    if (modalRef.current) {
      findFirstFocusableElement(modalRef.current);
    }
  }, []);

  return { modalRef, handleOnClose };
};

export default useModal;
