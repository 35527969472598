import React, { useState, useContext, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import { API, Auth } from 'aws-amplify';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import AnimatedRoute from '../components/AnimatedRoute';
import Button from '../components/Button';
import OshiSelect from '../components/OshiSelect';
import OshiInput from '../components/OshiInput';
import { CardscanContext } from '../utils/context';
import { setIsFetching } from '../actions';
import { segmentTypes } from '../constants';
import { createTrackEvent, TrackNonPolicyHolder } from '../actions/segment';
import { ROUTES } from '../constants/routes';
import getRepeatItemArray from '../utils/getRepeatItemArray';
import regex from '../utils/regex';
import getAge from '../utils/getAge';
import { palette } from '../theme/palette';

const listValues = ['Parent', 'Child', 'Spouse', 'Other'].map((value) => ({
  label: value,
  value,
}));

const NonPolicyHolderRoute = ({ setIsFetching, TrackNonPolicyHolder }) => {
  const [animationDirection, setAnimationDirection] = useState('mount');
  const [relationshipValue, setRelationshipValue] = useState('');
  const [showOtherField, setShowOtherField] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [insuranceInformation] = useContext(CardscanContext);
  const history = useHistory();

  useLayoutEffect(() => {
    TrackNonPolicyHolder();
    // eslint-disable-next-line
  }, []);

  const dobValidate = ({ policyHolderBirthday }) => {
    const errors = {};
    const splitBirthday = policyHolderBirthday
      .split(' / ')
      .join('/')
      .split('/');
    const month = Number(splitBirthday[0]);
    const day = Number(splitBirthday[1]);

    if (policyHolderBirthday?.length < 14) {
      errors.length = true;
      return errors;
    }

    const age = getAge(policyHolderBirthday);

    if (age <= 0) {
      errors.length = true;
      return errors;
    }

    if (age > 140) {
      errors.ageInvalid = true;
      return errors;
    }

    if (day < 1) {
      errors.day = true;
      return errors;
    }

    switch (month) {
      case 1:
      case 3:
      case 5:
      case 7:
      case 8:
      case 10:
      case 12:
        if (day > 31) {
          errors.day = true;
          return errors;
        }
        break;
      case 4:
      case 6:
      case 9:
      case 11:
        if (day > 30) {
          errors.day = true;
          return errors;
        }
        break;
      case 2:
        if (day > 29) {
          errors.day = true;
          return errors;
        }
        break;
      default:
        errors.month = true;
        return errors;
    }

    if (age < 18) {
      errors.age = true;
      return errors;
    }

    return errors;
  };

  const nameValidate = ({ policyHolderName }) => {
    const errors = {};
    if (policyHolderName?.length === 0) {
      errors.policyHolderName = true;
    }

    return errors;
  };

  const validate = ({ policyHolderName, policyHolderBirthday }) => ({
    ...nameValidate({ policyHolderName }),
    ...dobValidate({ policyHolderBirthday }),
  });

  const formik = useFormik({
    initialValues: {
      policyHolderName: '',
      policyHolderBirthday: '',
      policyHolderRelationship: '',
      policyHolderRelationshipOther: '',
    },
    validate,
    onSubmit: handleSubmit,
  });

  const isValid = () => {
    if (showOtherField && formik.values.policyHolderRelationshipOther === '')
      return false;
    return (
      formik.dirty &&
      Object.keys(formik.errors).length === 0 &&
      relationshipValue !== ''
    );
  };

  async function handleSubmit() {
    if (!isValid()) {
      setShowErrors(true);
    } else {
      setIsFetching(true);
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const userId = cognitoUser.attributes.sub;

      const policy_holder_dob = new Date(
        formik.values.policyHolderBirthday
      ).getTime();

      await API.post('oshiAPI', '/documents/insurance', {
        body: {
          ...insuranceInformation,
          is_policy_holder: false,
          relationship_to_policy_holder: showOtherField
            ? formik.values.policyHolderRelationshipOther
            : relationshipValue,
          policy_holder_name: formik.values.policyHolderName,
          policy_holder_dob,
          patient_cognito_id: userId,
        },
      })
        .then(() => {
          setIsFetching(false);
          history.push(ROUTES.FIRST_APPT_OVERVIEW);
        })
        .catch((error) => {
          console.error(error);
          createTrackEvent(segmentTypes.SERVER_ERROR, `Server error`);
          setIsFetching(false);
          history.push('network-error', { redirectLink: window.location.href });
        });
    }
  }

  const getErrorMessage = () => {
    if (!showErrors) return;

    if (
      formik.errors.ageInvalid ||
      formik.errors.length ||
      formik.errors.day ||
      formik.errors.month
    ) {
      return 'Please enter a valid date of birth.';
    }

    if (formik.errors.age) {
      return 'The policy holder must be at least 18 years of age.';
    }

    if (formik.errors.policyHolderName) {
      return 'Enter a first name';
    }
  };

  return (
    <AnimatedRoute
      title='Policy holder information'
      preHeader='Insurance information'
      showProgressBar={false}
      animationDirection={animationDirection}
      setAnimationDirection={setAnimationDirection}
    >
      <Form>
        <OshiInput
          id='policyHolderName'
          autofocus
          type='text'
          label='Policy holder full name'
          maskPlaceholder=''
          segmentLabel='Policy Holder - Full Name'
          value={formik.values.policyHolderName}
          onChange={formik.handleChange}
          mask={getRepeatItemArray(regex.name, 50)}
        />
        <OshiInput
          id='policyHolderBirthday'
          autoFocus
          type='tel'
          pattern='\d*'
          label='Policy holder date of birth'
          isValid={() => isValid()}
          formik={formik}
          maskPlaceholder=''
          onChange={formik.handleChange}
          value={formik.values.policyHolderBirthday}
          segmentLabel='Policy Holder - Birthday'
          mask='99 / 99 / 9999'
          secondaryLabel='mm / dd / yyyy'
          clearParentErrorOnFocus={() => setShowErrors(false)}
        />

        {showErrors && (
          <ErrorContainer>
            <Error>{getErrorMessage()}</Error>
          </ErrorContainer>
        )}

        <DropdownContainer>
          <OshiSelect
            id='PolicyHolderRelationship'
            onChange={(relationship) => {
              setRelationshipValue(relationship.value);
              if (relationship.value === 'Other') {
                setShowOtherField(true);
              } else {
                setShowOtherField(false);
              }
            }}
            data={listValues}
            label='Your relationship to policy holder'
          />

          {showOtherField && (
            <OshiInput
              id='policyHolderRelationshipOther'
              autofocus
              type='text'
              maskPlaceholder=''
              label='Other Relationship'
              segmentLabel='Policy Holder - Relationship'
              value={formik.values.policyHolderRelationshipOther}
              onChange={formik.handleChange}
              mask={getRepeatItemArray(regex.name, 50)}
            />
          )}
        </DropdownContainer>
        <Button
          disabled={
            (!formik.dirty && !formik?.initialStatus?.existingValues) ||
            formik.errors.policyHolderName ||
            relationshipValue === '' ||
            (showOtherField &&
              formik.values.policyHolderRelationshipOther === '')
          }
          styles={{ width: '100%' }}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Form>
    </AnimatedRoute>
  );
};

const ErrorContainer = styled.div`
  width: 100%;
`;
const Error = styled.p`
  font-family: 'Usual';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 168%;
  /* or 20px */

  letter-spacing: 0.04em;
  font-feature-settings: 'liga' off;
  color: ${palette.error};
`;

const DropdownContainer = styled.div`
  margin: 24px 0;
  width: 100%;
`;

const Form = styled.form`
  width: 100%;
  flex: 1;
`;

export default connect(null, { setIsFetching, TrackNonPolicyHolder })(
  NonPolicyHolderRoute
);
