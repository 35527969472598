import React from 'react';
import styled from 'styled-components';

import closeIcon from '../img/closeIcon.png';
import OshiHeader from './OshiHeader';
import useModal from '../hooks/useModal';

const Modal = ({
  id = '',
  onClose,
  children,
  header,
  containerStyle,
  hideCloseButton,
  ariaRole = 'dialog',
  ariaLabelledBy = null,
  ariaDescribedBy = null,
}) => {
  const { modalRef, handleOnClose } = useModal({ onClose });

  return (
    <PageContainer>
      <Overlay />
      <FlexContainer>
        <ContentContainer
          style={containerStyle}
          id={id}
          role={ariaRole}
          ref={modalRef}
          aria-modal='true'
          aria-labelledby={ariaLabelledBy || ''}
          aria-describedby={ariaDescribedBy || ''}
        >
          {header && <OshiHeader />}
          {!hideCloseButton && (
            <CloseContainer>
              <CloseBtn
                type='button'
                aria-label='Close Modal'
                onClick={(ev) => handleOnClose(ev)}
              >
                <Icon src={closeIcon} />
              </CloseBtn>
            </CloseContainer>
          )}
          {children}
        </ContentContainer>
      </FlexContainer>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 200;
  @media (max-width: 768px) {
    height: 100%;
    z-index: 12;
  }
`;
const Overlay = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
  opacity: 0.8;
  z-index: 0;
  @media (max-width: 900px) and (orientation: landscape) {
    height: 100%;
    width: 100vw;
  }
`;
const FlexContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  z-index: 10;
`;
const ContentContainer = styled.div`
  position: relative;
  top:  50%;
  transform: translate(0, -50%);
  width: 624px;
  min-height: 357px;
  background: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  padding-top: ${(props) => (props.native ? '8%' : '0')};
  align-items: center;
`;

const CloseContainer = styled.div`
  width: 95%;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 768px) {
    position: relative;
    top: 10px;
    justify-content: flex-start;
    margin-top: 0px;
    padding: 0px 0px;
    height: 30px;
  }
`;

const CloseBtn = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  width: 24px;
  height: 24px;
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
  @media (max-width: 420px) {
    position: absolute;
    z-index: 1000;
  }
`;
export default Modal;
