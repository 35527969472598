import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import OshiInput from '../components/OshiInput';
import { HeaderLogoBig } from '../assets/svgs';
import { palette } from '../theme/palette';
import useSigninPageUILogic from '../hooks/useSigninPageUILogic';
import Button from '../components/Button';
import OshiLink from '../components/OshiLink';
import MarketingSVG from '../img/signin.svg';
import { setIsFetching } from '../actions';
import {
  SignInCreateAccountBtnPressed,
  SigninInvalidEmailOrPasswordEntered,
  ForgotPasswordBtnPressed,
  SignInSigninBtnPressed,
} from '../actions/segment';

const SignIn = ({
  setIsFetching,

  SigninInvalidEmailOrPasswordEntered,
  ForgotPasswordBtnPressed,
  SignInCreateAccountBtnPressed,
  SignInSigninBtnPressed,
}) => {
  const {
    formik,
    showErrors,
    clearParentErrorOnFocus,
    handleOnKeyPress,
    handleOnSubmit,
    history,
  } = useSigninPageUILogic({
    setIsFetching,
    SignInSigninBtnPressed,
    SigninInvalidEmailOrPasswordEntered,
  });
  const isValid = formik.dirty && Object.keys(formik.errors).length === 0;

  return (
    <Container>
      <LeftContainer>
        <HeaderContainer>
          <HeaderLogoBig />
        </HeaderContainer>
        <MainContainer>
          <WelcomeContainer>
            <WelcomeText>Welcome back!</WelcomeText>

            <DigestiveCareText>
              The digestive care you deserve is just a few steps away.
            </DigestiveCareText>
          </WelcomeContainer>
          <FormContainer onKeyDown={handleOnKeyPress}>
            <InputsContainer>
              <InputContainer>
                <OshiInput
                  id='email'
                  autoFocus
                  type='text'
                  label='Email'
                  isValid={isValid}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  clearParentErrorOnFocus={clearParentErrorOnFocus}
                  segmentLabel='Sign In - Email Input'
                  error={showErrors && formik.errors.email}
                />
              </InputContainer>

              <InputContainer>
                <OshiInput
                  id='password'
                  type='password'
                  label='Password'
                  isValid={isValid}
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  segmentLabel='Sign In - Password Input'
                  clearParentErrorOnFocus={clearParentErrorOnFocus}
                  error={showErrors && formik.errors.password}
                />

                {Object.keys(formik.errors).length > 0 && showErrors && (
                  <ErrorContainer>
                    <ErrorText>
                      {formik.errors.email || formik.errors.password}
                    </ErrorText>
                  </ErrorContainer>
                )}
              </InputContainer>
            </InputsContainer>
            <ButtonsContainer>
              <Button disabled={!formik.dirty} onClick={handleOnSubmit}>
                Sign In
              </Button>

              <ForgotPasswordContaienr>
                <OshiLink
                  styles={{
                    marginTop: 20,
                    color: palette.coolGray500,
                  }}
                  containerStyles={{
                    justifyContent: 'center',
                  }}
                  buttonTitle='Forgot password'
                  onClick={() => {
                    ForgotPasswordBtnPressed();
                    history.push('reset-password-email');
                  }}
                />
              </ForgotPasswordContaienr>
            </ButtonsContainer>
            <NoAccountContaienr>
              <DigestiveCareText style={{ marginBottom: '0px' }}>
                No account? No problem.
              </DigestiveCareText>

              <ForgotPasswordContaienr>
                <OshiLink
                  styles={{ color: palette.navy500 }}
                  containerStyles={{
                    justifyContent: 'center',
                  }}
                  buttonTitle='Create account.'
                  onClick={() => {
                    SignInCreateAccountBtnPressed();
                    history.replace('/name');
                  }}
                />
              </ForgotPasswordContaienr>
            </NoAccountContaienr>
          </FormContainer>
        </MainContainer>
      </LeftContainer>

      <RightContainer tabIndex={-1}>
        <RigthInnerContainerTop>
          <RigthInnerContainerTopText>
            <img
              src={MarketingSVG}
              alt='What Oshi Health members are saying'
              aria-describedby='marketingDesc'
            />
            <HiddenText id='marketingDesc' aria-hidden="true">
              I have been struggling with digestive issues for awhile now. Oshi
              Health was the first time I felt listened to. I finally found
              relief - without needing costly procedures or just being
              prescribed another medication. Jennifer from Texas
            </HiddenText>
          </RigthInnerContainerTopText>
        </RigthInnerContainerTop>
      </RightContainer>
    </Container>
  );
};

const Container = styled.div`
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: row;
`;

const LeftContainer = styled.div`
  display: flex;
  flex: 1;
  width: 60%;
  flex-direction: column;
  align-items: center;
  background: transparent;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const MainContainer = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 24px;
  margin-right: 24px;
  max-width: 500px;
  width: calc(100% - 48px);
  @media only screen and (min-width: 549px) {
    width: 100%;
  }
`;

const HeaderContainer = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 20px;
  margin-top: 56px;
  margin-bottom: 12%;
  @media only screen and (min-width: 1136px) {
    margin-top: 96px;
    margin-bottom: 18%;
  }
`;

const WelcomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
`;

const WelcomeText = styled.h2`
  font-family: 'Source Serif Pro', serif;
  font-size: 32px;
  margin-bottom: 15px;
  color: ${palette.navy500};
`;

const DigestiveCareText = styled.p`
  font-family: 'Usual', serif;
  font-size: 15px;
  color: ${palette.coolGray};
  line-height: 150%;
  text-align: center;
  @media only screen and (max-width: 500px) {
    max-width: 80%;
  }
`;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10%;

  @media only screen and (max-width: 500px) {
    min-width: 100%;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 500px) {
    min-width: 90%;
  }
`;

const ForgotPasswordContaienr = styled.div`
  display: flex;
  width: 100%;
`;

const NoAccountContaienr = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
  margin-bottom: 12px;
`;

const InputContainer = styled.div``;

const RightContainer = styled.aside`
  display: flex;
  flex: 1;
  position: relative;
  background: #355074;
  width: 40%;
  flex-direction: column;
  max-height: 100%;
  justify-content: flex-end;
  @media only screen and (max-width: 1135px) {
    display: none;
  }
`;

const RigthInnerContainerTop = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: 2;
`;

const RigthInnerContainerTopText = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 5px;
`;

const ErrorText = styled.div`
  color: ${palette.error};
  font-family: 'Usual', serif;
  font-size: 12px;

  @media only screen and (max-width: 500px) {
    text-align: center;
  }
`;

const HiddenText = styled.p`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  color: transparent;
  padding: 0;
  position: absolute;
  width: 1px;
`;

export default connect(null, {
  setIsFetching,

  SigninInvalidEmailOrPasswordEntered,
  ForgotPasswordBtnPressed,
  SignInCreateAccountBtnPressed,
  SignInSigninBtnPressed,
})(SignIn);
