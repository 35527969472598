import React, {useState, useContext, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { API, Auth } from 'aws-amplify';
import { connect } from 'react-redux';

import AnimatedRoute from '../components/AnimatedRoute';
import Button from '../components/Button';
import { segmentTypes } from '../constants';
import { createTrackEvent } from '../actions/segment';
import { palette } from '../theme/palette';
import { CardscanContext } from '../utils/context';
import { setIsFetching } from '../actions';
import { TrackPolicyHolder } from '../actions/segment';
import { ROUTES } from '../constants/routes';

const PolicyHolderRoute = ({ setIsFetching, TrackPolicyHolder }) => {
  const [animationDirection, setAnimationDirection] = useState('mount');
  const [insuranceInformation, ] = useContext(CardscanContext);
  const history = useHistory();

  useLayoutEffect(() => {
    TrackPolicyHolder();
    // eslint-disable-next-line
  }, []);

  const handleIsPolicyHolder = async () => {
    setIsFetching(true);
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const userId = cognitoUser.attributes.sub;

    await API.post('oshiAPI', '/documents/insurance', {
      body: { ...insuranceInformation, is_policy_holder: true, patient_cognito_id: userId }
    }).then(() => {
      setIsFetching(false);
      history.push(ROUTES.FIRST_APPT_OVERVIEW);
    }).catch(error => {
      console.error(error);
      createTrackEvent(segmentTypes.SERVER_ERROR, `Server error`);
      setIsFetching(false);
      history.push('network-error', { redirectLink: window.location.href });
    });
  }

  const handleIsNotPolicyHolder = () => {
    setAnimationDirection('unmount');
  }

  return (
    <AnimatedRoute
      nextRoute='/policy-holder-relationship'
      preHeader='Insurance information'
      title='Are you the policy holder?'
      showProgressBar={false}
      animationDirection={animationDirection}
      setAnimationDirection={setAnimationDirection}
    >
      <DescriptionText>Your card was scanned successfully. Are you the policy holder?</DescriptionText>
      <ButtonRowcontainer>
        <Button
          styles={{ fontSize: '13px', width: '100%' }}
          onClick={handleIsPolicyHolder}
        >Yes, I'm the policy holder.</Button>
        <Button
          styles={{ fontSize: '13px', width: '100%' }}
          onClick={handleIsNotPolicyHolder}
        >No, I'm not the policy holder.</Button>
      </ButtonRowcontainer>
    </AnimatedRoute>
  );
};

const ButtonRowcontainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: space-between;
  padding: 24px 0;
  width: 100%;
  flex: 1;
  @media only screen and (max-width: 769px) {
    flex-direction: column;
  }
`;

const DescriptionText = styled.p`
  color: ${palette.coolGray};
  font-family: 'Usual';
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  max-width: 550px;
  width: 100%;
`;

export default connect(null, { setIsFetching, TrackPolicyHolder })(PolicyHolderRoute);