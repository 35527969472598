import React from 'react';

export const defaultSearchFn = (searchableList, valueToSearch) => {
  return valueToSearch.length > 1
    ? searchableList.filter((it) => {
        const newValue = valueToSearch.toLowerCase();
        return it.label.toLowerCase().search(newValue) >= 0;
      })
    : [];
};

/**
 * @function useSearchableList
 * @module hooks/useSearchableList
 * @param {Function|Promise} searchFn - Function or Promise that returns a filtered list given its criteria
 * @param {Array} data - List of searchable items
 * @returns {{list: Array, setList: Function, setResultFn: Function}}}
 */
const useSearchableList = ({ searchFn, data }) => {
  const [list, setList] = React.useState([]);
  const cb =
    searchFn instanceof Promise
      ? async (value) => {
          const result = await searchFn(data, value);
          setList(result);
        }
      : (value) => {
          setList(searchFn(data, value));
        };
  const setResultFn = React.useCallback(cb, [searchFn, data]);
  return {
    list,
    setResultFn,
  };
};

export default useSearchableList;
