import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import OshiContentLink from './OshiContentLink';
import DLiPhoneApp from '../img/AppStore.svg';
import DLAndroidApp from '../img/GooglePlay.svg';
import WebQr from '../img/web-qr.svg';
import { palette } from '../theme/palette';

const IPHONE = 'iPhone';
const ANDROID = 'Android';
const WEB = 'Web';

function OshiDownloadDisplay() {
  const [deviceType, setDeviceType] = useState(null);

  useEffect(() => {
    if (/Android/i.test(navigator.userAgent)) {
      setDeviceType(ANDROID);
    } else if (/iPhone/i.test(navigator.userAgent)) {
      setDeviceType(IPHONE);
    } else {
      setDeviceType(WEB);
    }
  }, []);

  const handleStoreImageClick = React.useCallback((type) => {
    if (!type) return '';
    return type === IPHONE
      ? 'https://apps.apple.com/us/app/oshi-health/id1518331430'
      : 'https://play.google.com/store/apps/details?id=com.oshimobile';
  }, []);

  function getDeviceType() {
    switch (deviceType) {
      case IPHONE:
        return (
          <OshiContentLink
            href={handleStoreImageClick(deviceType)}
            imgSrc={DLiPhoneApp}
            imgAlt='Download for iPhone'
          />
        );
      case ANDROID:
        return (
          <OshiContentLink
            href={handleStoreImageClick(deviceType)}
            imgSrc={DLAndroidApp}
            imgAlt='Download for Android'
          />
        );
      case WEB:
        return (
          <DLContainer>
            <DeviceDLContainer>
              <TextContainer>
                <span>
                  Scan the code on your right with your smart phone camera to
                  download our mobile app.
                </span>
              </TextContainer>
              <DevicesContainer>
                <OshiContentLink
                  href={handleStoreImageClick(IPHONE)}
                  imgSrc={DLiPhoneApp}
                  imgAlt='Download for iPhone'
                />
                <OshiContentLink
                  href={handleStoreImageClick(ANDROID)}
                  imgSrc={DLAndroidApp}
                  imgAlt='Download for Android'
                />
              </DevicesContainer>
            </DeviceDLContainer>
            <QrContainer>
              <img style={{ height: '137px' }} src={WebQr} alt='Scan QR code' />
            </QrContainer>
          </DLContainer>
        );
      default:
        return null;
    }
  }

  return <Container>{getDeviceType()}</Container>;
}

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-bottom: 40px;
  @media only screen and (min-width: 769px) {
    padding-bottom: 48px;
    justify-content: left;
  }
`;
const DLContainer = styled.div`
  border: 1px solid ${palette.tan200};
  border-radius: 8px;
  justify-content: space-between;
  display: flex;
  padding: 12px 16px;
  width: 100%;
`;

const QrContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const DeviceDLContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-evenly;
  margin-right: 12px;
`;

const TextContainer = styled.div`
  display: flex;
  font-family: 'Usual';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 168%;
  /* or 24px */

  letter-spacing: 0.04em;
  font-feature-settings: 'liga' off;

  color: ${palette.coolGray};
  margin-bottom: 15px;
  @media only screen and (min-width: 769px) {
    margin-bottom: 0;
  }
`;

const DevicesContainer = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & > a {
    margin-right: 0;
    margin-bottom: 12px;
  }
  & > a:last-child {
    margin-bottom: 0;
  }
  & > a > img {
    width: calc(80% - 12px);
  }
  @media only screen and (min-width: 769px) {
    flex-direction: row;
    & > a {
      margin-bottom: 0;
    }
    & > a:first-child {
      margin-right: 12px;
    }
    & > a > img {
      width: auto;
    }
  }
`;

export default React.memo(OshiDownloadDisplay);
